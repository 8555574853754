const discoveryEndpoint = "https://corpextdev.b2clogin.com/corpextdev.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_OIDC_SIGNIN";
const clientId = "56a1a501-7699-499f-9492-47e9430edecb";
const clientSecret = "kYy8Q~94-PSiztvugGkqqSAOa2bYrFqt5VaYtcrU";
const scope = "openid offline_access";
const redirectUri = "https://irataxcreditcalculator.corp.global";
 
export async function getOpenIDConfig() {
  try {
    const response = await fetch(discoveryEndpoint);
    const data = await response.json();
    console.log('OpenID Config:', data);
    return data;
  } catch (error) {
    console.error('Error fetching OpenID configuration:', error);
    return null;
  }
}

export async function redirectToLogin() {
  const config = await getOpenIDConfig();
 
  if (!config || !config.authorization_endpoint) {
    console.error("Authorization endpoint not found in OpenID configuration.");
    return;
  }
 
  const authUrl = `${config.authorization_endpoint}&client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;
  
  console.log("Redirecting to:", authUrl);
  window.location.href = authUrl;
}
 
export async function exchangeCodeForToken(code) {
  const config = await getOpenIDConfig();
  const tokenResponse = await fetch(config.token_endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: new URLSearchParams({
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: "authorization_code",
      redirect_uri: redirectUri,
      code,
    }),
  });
 
  return tokenResponse.json();
}
 
export async function handleAuthCallback() {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
 
  if (!code) {
    console.error('Authorization code not found');
    return redirectToLogin();
  }
 
  const tokenData = await exchangeCodeForToken(code);
 
  if (tokenData.id_token) {
    const idTokenClaims = JSON.parse(atob(tokenData.id_token.split(".")[1]));
    console.log('ID Token Claims:', idTokenClaims);
 
    const firstName = idTokenClaims.FirstName || idTokenClaims.firstname;
    if (firstName) {
      localStorage.setItem("user", JSON.stringify({idTokenClaims}));
      console.log('Welcome, ', firstName);
      return idTokenClaims;
    } else {
      console.warn('FirstName claim is missing.');
      redirectToLogin();
    }
  } else {
    console.error('ID Token not present in token response');
    redirectToLogin();
  }
}

  export function logout() {
    localStorage.clear();
    getOpenIDConfig().then(config => {
      if (config && config.end_session_endpoint) {
        const logoutUrl = `${config.end_session_endpoint}&post_logout_redirect_uri=${encodeURIComponent(redirectUri)}`;
        console.log("Logging out, redirecting to:", logoutUrl);
        window.location.href = logoutUrl;
      } else {
        console.error("End session endpoint not found in OpenID configuration.");
      }
    });
  }