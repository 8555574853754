import React, { useState, useEffect } from 'react';
import { AppBar, Container, Modal, Box, Button, CircularProgress, Alert, Snackbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Logo from '../images/logo-TT.svg';
import { StyledContainer } from './Header.style';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import { latestFileUpload } from '../helper/IraHelper';
import '../App.css';
 
const HeaderComp = ({ user, logout }) => {
  const [fontSize, setFontSize] = useState(11);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const isMobile = useMediaQuery('(max-width:600px)');
  const increaseFontSize = () => setFontSize((prev) => Math.min(prev + 1, 21));
  const decreaseFontSize = () => setFontSize((prev) => Math.max(prev - 1, 6));
 
  useEffect(() => {
document.documentElement.style.setProperty('--app-font-size', `${fontSize}px`);
  }, [fontSize]);
 
  const toggleDrawer = (open) => () => setDrawerOpen(open);
 
  const handleConfigFile = () => setOpenConfig(true);
  const handleCloseConfig = () => setOpenConfig(false);
 
  const handleFileChange = (event) => setFile(event.target.files[0]);
 
  const handleFileUpload = async () => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append('excel_file', file);
    try {
      const res = await latestFileUpload(formData);
      if (res.status === 200) {
        setAlertSeverity('success');
        setAlertMessage(res?.data?.message);
        setOpenSnackbar(true);
        setOpenConfig(false);
        window.location.reload();
      } else {
        setAlertSeverity('error');
        setAlertMessage(res?.data?.message || 'Failed to upload');
        setOpenSnackbar(true);
      }
    } catch (error) {
      setAlertSeverity('error');
      setAlertMessage('File upload failed. Please try again.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
 
  const handleCloseSnackbar = () => setOpenSnackbar(false);
 
  return (
    <StyledContainer style={{top:0}}>
      <AppBar position="static" className="topBar">
        <Container maxWidth="xl" className="barContainer">
          <img src={Logo} alt="trane logo" />
          <h1>System Price Calculator for IRA Tax Credits</h1>
          <IconButton className="hamburger" style={{width: isMobile ?"12%": '3%', marginRight:"0.1rem"}} edge="end" color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon className="hamburgerIcon" style={{width: '100%', height:"100%",color: "#7030a0"}}/>
          </IconButton>
        </Container>
      </AppBar>
 
      {/* Drawer for Sidebar */}
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 250,
            height: isMobile ? 'auto' : '100%',
            maxHeight: isMobile ? '50%' : '100%',
          },
        }}
      >
        <Box role="presentation" style={{background: "#b4c6e7"}} onClick={toggleDrawer(false)}>
        {/* <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}> */}
          <List style={{background: "#d2c5ed",paddingTop: "0"}}>
            <ListItem style={{background: "#7030a0",color: "#fff"}}>
              <ListItemText style={{fontWeight: "bolder"}} primary={`Welcome, ${user?.FirstName || localStorage.getItem("user")?.FirstName}`} />
            </ListItem>
            <ListItem button onClick={handleConfigFile}>
              <ListItemIcon style={{minWidth:"25px"}}><UploadFileIcon /></ListItemIcon>
              <ListItemText primary="Upload data" />
            </ListItem>
            <ListItem button onClick={decreaseFontSize}>
              <ListItemIcon style={{minWidth:"25px"}}><TextDecreaseIcon /></ListItemIcon>
              <ListItemText primary="Decrease font size" />
            </ListItem>
            <ListItem button onClick={increaseFontSize}>
              <ListItemIcon style={{minWidth:"25px"}}><TextIncreaseIcon /></ListItemIcon>
              <ListItemText primary="Increase font size" />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemIcon style={{minWidth:"25px"}}><LogoutIcon /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
 
      {/* Modal for Config */}
      <Modal open={openConfig} onClose={handleCloseConfig}>
      <>
        <Box sx={{ ...modalStyle }}>
        <div className='inputTable' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h2 mb-2 >Upload an excel file to update the data</h2>
            <IconButton 
            aria-label="close"
                            onClick={handleCloseConfig} 
             sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#000',
                            }} 
              disabled={loading}
              >
              <CloseIcon />
            </IconButton>
          </div>
          <div className='inputTable' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <input type="file" accept=".xlsx" onChange={handleFileChange} />
          <Button variant="contained" color="primary" style={{background:'#7030a0'}} onClick={handleFileUpload} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
          </div>
        </Box>
 
      {/* Snackbar for Alerts */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
        </>
      </Modal>
    </StyledContainer>
  );
};
 
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  pt:2
};
 
export default HeaderComp;