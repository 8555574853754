import axios from 'axios';
import Config from '../common/config/config';

 
const api = axios.create({
  baseURL:Config.API_URL, // Replace with your API base URL
});
export const getFirstTableData = async () => {
  try {
    const response = await api.get('/get_first_table');
    return response.data;
  } catch (error) {
    console.error('Error fetching first table data:', error);
    throw error;
  }
};
export const secondTableDataApi = async (payload) => {
  try {
    const response = await api.post('/get_second_table',payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching first table data:', error);
    throw error;
  }
};
export const onChangeTableDataApi = async (payload) => {
  try {
    const response = await api.post('/get_onchange_values',payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching first table data:', error);
    throw error;
  }
};
export const lookTableDataApi = async (payload) => {
  try {
    const response = await api.post('/get_lookup_table',payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching first table data:', error);
    throw error;
  }
};

export const latestFileUpload = async (obj) => {
    let isSuccess = false;
    let data;
    try {
        console.log('Initiating API call...');
        console.log('FormData:', obj);
        const res = await api.post(`/upload/excel/`, obj, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log('API response:', res);
        if (typeof res === "object" && res != null && res.status === 200) {
            if (res?.status === 200) {
                isSuccess = true;
                data =res;
                return data;
            }
        }
    } catch (err) {
        const errorMessage = err?.message;
        console.error('API call failed:', errorMessage);
        isSuccess = false;
        data= err.response;
    }
    return data;
};
