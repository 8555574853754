const devData = {
    API_URL: process.env.REACT_APP_DEV_API
};

const prodData = {
    API_URL: process.env.REACT_APP_PROD_API
};

function getConfigData() {
    switch (process.env.NODE_ENV) {
        case 'development':
            return devData;
        case 'production':
            return prodData;
        default:
            return devData;
    }
}

const configData = getConfigData()

export default configData