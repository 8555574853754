import Styled from 'styled-components'

export const StyledDiv = Styled.div`
    min-height:calc(100vh - 75px);
.sTableHead{
    color: #fff  !important;
    background: #0070c0;
    padding:0;
}
.sTableSubHead{
    color: #fff  !important;
    background: #0070c0;
    td{
        color:#fff;
    }
}
.sTableSubHead &&& {
    color: #BF4F74;
    font-weight: bold;
  }
.sTableRows td:first-Child{
    background: #bfbfbf;
    color: #060606;
}

.instructionBtn{
    border: 1px solid;
    border-radius: 25px;
    background: #7030a0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // line-height: 0;
    padding: 20px;
}
.instructionBtn:hover{
    background: #b447c3;
}
.tableContainer{
    display: flex;
    // justify-content: center;
    align-items: start;
    min-height: calc(100vh - 99px);
    max-width: 100%;
    flex-direction: column;
    .instructionBox{
        margin: 1rem 0px;
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }
    }
}
.inputTable{
    .tableContainer{
        box-shadow: none;
    border: unset;
    }
    thead{
        th{
            background: #7030a0;
            color: #fff;
            padding: 0.1rem;
        }
        th:first-child{
                // background: #b8c6e7;
                // color: #2b56ba;
                background: #0070c0;
                color: #fff;
                
            
        }
        th:last-child{
                background: transparent;
                color: #2b56ba;
            
        }
    }
    tbody{
        tr td:first-Child{
            background: #b4c6e7;
            color: #050506;        
                padding: 0 0.5rem;
                width: max-content;
                text-wrap: nowrap;
                border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
        tr:first-child td:first-Child{
            background: #bfbfbf;
            color: #030303;
            padding:0;
        }
    }
}
.resultArea{
    tbody{
        tr td:first-Child{
            background: #b8c6e7;
                color: #2b56ba;
        }
    }
}
.selectColumn{
    padding:0;
}
.selectColumn div{
    width: 100%;
    border-radius:0;
}
.tableSelect div{
    padding: 0 32px 0 5px;
    -webkit-text-fill-color: rgba(0, 0, 0) !important;
}
.tableFields,.tableFields div, .tableFields div >input{
    padding:0 !important;
    text-align: center;
   border-radius: 0;
   color: inherit;
}
.tableFields div{
    width:100%
}
.tDisabled{
    cursor: not-allowed; 
}
.tDisabled div input{
    color: #fff !important;
    -webkit-text-fill-color: #000 !important;
    cursor: not-allowed;
}
.inputRows .tDisabled div input{
    color: #fff !important;
    -webkit-text-fill-color: #000 !important;
    cursor: not-allowed;
}
.homeOwner .tDisabled div input{
    height:31px;
}
.inputRows .inputColor div input,.seerData div input{
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
    cursor: not-allowed;
}
`