import React, { useEffect, useState } from "react";
import './App.css';
import TableLayout from './Tables/TableLayout';
import HeaderComp from './Components/HeaderComp';
import FooterComp from './Components/FooterComp';
import { handleAuthCallback, redirectToLogin, logout } from "./auth/AuthService";
import { StyledDiv } from './App.style';
import {Backdrop, CircularProgress, Box } from '@mui/material';
function App() {
  const [user, setUser] = useState(null);
 
  useEffect(() => {
    const storedUser = localStorage.getItem("user")?.idTokenClaims;
    
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      handleAuthCallback().then((claims) => {
        if (claims) setUser(claims);
      });
    }
  }, []);
 
  if (!user) {
    // redirectToLogin(); // Redirect if no user
    return (
      <div className='loader'>
      {/* <Typography>Loading...</Typography>
      <CircularProgress style={{ marginTop: 20 }} /> */}
      <Backdrop
          sx={{ color: '#fff', zIndex: 100001 }}
          open={true}
          // onClick={handleLoadClose}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
  </div>
    );
  }
  return (
    <div className="App">
      <HeaderComp user={user} logout={logout}/>
      {/* <HeaderComp /> */}
      <StyledDiv >
        <TableLayout/>
      </StyledDiv>
        <FooterComp />
    </div>
  );
}

export default App;
