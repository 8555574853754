import React from 'react';
import { Dialog, DialogContent, DialogTitle,IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material';
import instructionVideo from '../video/IRA_Video_20240819_121814_Meeting_Recording.mp4';


const VideoModal = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>instruction video</DialogTitle>
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          &#x2715;
        </IconButton>
      <DialogContent style={{padding:'20px 14px'}}>
      <iframe width="560" height="315" src={instructionVideo} title="IRA Video 20240819 121814 Meeting Recording" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </DialogContent>
    </Dialog>
  );
};


export default VideoModal;