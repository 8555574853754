import React from 'react';
import {AppBar, Container,Toolbar } from '@mui/material';
import { StyledContainer } from './Header.style';
const FooterComp =()=>{
    
    return (
              <StyledContainer style={{bottom:0}}>
        <AppBar position='static' className='footer'>
              <Container maxWidth="xl" className='barContainer'>
                    <h3>© {new Date().getFullYear()} Copyright: Trane Technologies <span className='version'>V1.0.0</span></h3>
            </Container>
        </AppBar>
                </StyledContainer>
    )
}

export default FooterComp;