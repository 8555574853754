import Styled from 'styled-components'

export const StyledContainer = Styled.div`
position: sticky;
z-index: 1;
.topBar{
    background: #b4c6e7;
}
.barContainer{
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: #000000;
    background: #b4c6e7;
    max-width:100%;
    img{
        width: 8%;
        height: 8%;
    }
    h1{
        font-size: 2rem !important;
    }
    h1,h3{
        text-align: center;
        width: 90%;
        margin: 0;
    }
    h3{
        width:100%;
        font-size: 16px !important;
    }
    .version{
        float: right;
        height: inherit;
        line-height: 2;
    }
    .fontArea{
        display: flex;
        gap:1rem;
        padding-right:1rem;
    }
    .fontChanger{
        cursor:pointer;
        background: #9982ef;
        padding: 0.3rem;
        border: 1px solid #a49dec;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
`
