import React, { useState, useEffect, useRef } from 'react';
import { Backdrop, Container, Grid, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Select, MenuItem, InputLabel, FormControl, Typography, InputAdornment, CircularProgress } from '@mui/material';
import InstructImage from '../images/north_south.png';
import { getFirstTableData, secondTableDataApi, onChangeTableDataApi, lookTableDataApi } from '../helper/IraHelper';
import VideoModal from '../Components/VideoModal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useReactToPrint } from 'react-to-print';


const TableLayout = () => {

    const inputEntryTable = [
        ['Equipment Cost (Actual)', '', '', '', '', '', '', ""],
        ['Labor and Material Cost (Actual)', '', '', '', '', '', '', ""],
        ['Margin Dollars', '', '', '', '', '', '', ""],
        ['Local Rebate', '', '', '', '', '', '', ""],
        ['IRA Tax Credit: Outdoor HP', '', '', '', '', '', '', ""],
        ['IRA Tax Credit: Furnace', '0', '600', '0', '600', '600', '600', "600"],

    ]
    const resultTable = [
        ['System Price to Homeowner (Before Credits and Rebates)', 8190, 10010, 10270, 11570, 13520, 13910, 14950],
        ['System Price to Homeowner (After Credits and Rebates)', 8190, 9410, 7670, 11570, 10920, 11910, 12350],
        ['Margin for Dealer', 1890, 2310, 2370, 2670, 3120, 3210, 3450],
    ];
    const [loading, setLoading] = useState(true);
    const pdfContent = useRef(null);
    const videoBtn = useRef(null);
    const pdfBtn = useRef(null);
    const lookupRef = useRef(null);
    const [openLoad, setOpenLoad] = React.useState(false);
    const [firstTData, setFirstTData] = useState(null);
    const [secondTData, setSecondTData] = useState(null);
    const [lookUpValidate, setLookUpValidate] = useState({
        "11DigitModel": [
            "9V2B040U3VS",
            "9V2B060D3VS",
            "9V2B060U3VS",
            "9V2B080D4VS",
            "9V2B080U4VS",
            "9V2C100D4VS",
            "9V2C100U4VS",
            "9V2D120U5VS",
            "UHMB060ACV3",
            "UHMB060CCV3",
            "UHMB080ACV3",
            "UHMB080CCV3",
            "UHMD120ACV5",
            "UHMD120CCV5"
        ]
    });
    const [open, setOpen] = useState(false);
    const [isMargin, setIsMargin] = useState(false);

    const [lookUpRegion, setLookUpRegion] = useState();
    const [lookUpUnitTonnage, setLookUpUnitTonnage] = useState(null);
    const [lookUpAcHP, setLookUpAcHP] = useState(null);
    const [lookUpFrnAh, setLookUpFrnAh] = useState(null);
    const [lookUpBrand, setLookUpBrand] = useState(null);
    const [lookUpMargin, setLookUpMargin] = useState(null);
    const [lookUpLast, setLookUpLast] = useState(null);

    const [odTDataRegion, setOdTDataRegion] = useState(null);
    const [odTDataUnitTonnage, setOdTDataUnitTonnage] = useState(null);
    const [odTDataAcHP, setOdTDataAcHP] = useState(null);
    const [odTDataFrnAh, setOdTDataFrnAh] = useState(null);
    const [odTDataBrand, setOdTDataBrand] = useState(null);
    const [odTDataMargin, setOdTDataMargin] = useState(null);
    const [odTDataLast, setOdTDataLast] = useState(null);

    const [coilTDataRegion, setCoilTDataRegion] = useState(null);
    const [coilTDataUnitTonnage, setCoilTDataUnitTonnage] = useState(null);
    const [coilTDataAcHP, setCoilTDataAcHP] = useState(null);
    const [coilTDataFrnAh, setCoilTDataFrnAh] = useState(null);
    const [coilTDataBrand, setCoilTDataBrand] = useState(null);
    const [coilTDataMargin, setCoilTDataMargin] = useState(null);
    const [coilTDataLast, setCoilTDataLast] = useState(null);

    const [ahriTDataRegion, setAhriTDataRegion] = useState(null);
    const [ahriTDataUnitTonnage, setAhriTDataUnitTonnage] = useState(null);
    const [ahriTDataAcHP, setAhriTDataAcHP] = useState(null);
    const [ahriTDataFrnAh, setAhriTDataFrnAh] = useState(null);
    const [ahriTDataBrand, setAhriTDataBrand] = useState(null);
    const [ahriTDataMargin, setAhriTDataMargin] = useState(null);
    const [ahriTDataLast, setAhriTDataLast] = useState(null);

    const [odFilterDataRegion, setOdFilterDataRegion] = useState(null);
    const [odFilterDataUnitTonnage, setOdFilterDataUnitTonnage] = useState(["4A7A5018N1", "N/A"]);
    const [odFilterDataAcHP, setOdFilterDataAcHP] = useState(null);
    const [odFilterDataFrnAh, setOdFilterDataFrnAh] = useState(null);
    const [odFilterDataBrand, setOdFilterDataBrand] = useState(null);
    const [odFilterDataMargin, setOdFilterDataMargin] = useState(null);
    const [odFilterDataLast, setOdFilterDataLast] = useState(null);

    const [inputEntryTableData, setInputEntryTableData] = useState(inputEntryTable); // State for Input Table
    const [resultTableData, setResultTableData] = useState(resultTable);
    const [openModal, setOpenModal] = useState(false);
    const [mainRegion, setMainRegion] = useState("South");
    const [mainUnitTonnage, setMainUnitTonnage] = useState("2");
    const [mainAcHp, setMainAcHp] = useState("HP");
    const [mainfrnAh, setMainfrnAh] = useState("FRN");
    const [mainBrand, setMainBrand] = useState("TRANE");
    const [mainMargin, setMainMargin] = useState("10%");

    const [productRegion, setProductRegion] = useState("4TWR4");
    const [productUnitTonnage, setProductUnitTonnage] = useState("4TWL5");
    const [productAcHp, setProductAcHp] = useState("4TWR6");
    const [productfrnAh, setProductfrnAh] = useState("4TWX7");
    const [productBrand, setProductBrand] = useState("4TWV8");
    const [productMargin, setProductMargin] = useState("4TWL9");
    const [productLast, setProductLast] = useState("4TWV0");

    const [oDModelRegion, setODModelRegion] = useState("N/A");
    const [oDModelUnitTonnage, setODModelUnitTonnage] = useState("4TWL5024N1");
    const [oDModelAcHp, setODModelAcHp] = useState("4TWR6024N1");
    const [oDModelfrnAh, setODModelfrnAh] = useState("4TWX7024E1");
    const [oDModelBrand, setODModelBrand] = useState("4TWV8X24A1");
    const [oDModelMargin, setODModelMargin] = useState("4TWL9024A1");
    const [oDModelLast, setODModelLast] = useState("4TWV0X24A1");



    const [qRODRegion, setQRODRegion] = useState("0");
    const [qRODUnitTonnage, setQRODUnitTonnage] = useState("669");
    const [qRODAcHp, setQRODAcHp] = useState("243");
    const [qRODfrnAh, setQRODfrnAh] = useState("252");
    const [qRODBrand, setQRODBrand] = useState("67");
    const [qRODMargin, setQRODMargin] = useState("94");
    const [qRODLast, setQRODLast] = useState("65");

    const [equipRegion, setEquipRegion] = useState("N/A");
    const [equipUnitTonnage, setEquipUnitTonnage] = useState("*DD2B060A9V3");
    const [equipAcHp, setEquipAcHp] = useState("N/A");
    const [equipfrnAh, setEquipfrnAh] = useState("*DD2B060ACV3");
    const [equipBrand, setEquipBrand] = useState("*DD2B060ACV3");
    const [equipMargin, setEquipMargin] = useState("*DD2B060A9V3+RP");
    const [equipLast, setEquipLast] = useState("*DD2B060ACV3");

    const [nODModelRegion, setNODModelRegion] = useState(null);
    const [nODModelUnitTonnage, setNODModelUnitTonnage] = useState(null);
    const [nODModelAcHp, setNODModelAcHp] = useState(null);
    const [nODModelfrnAh, setNODModelfrnAh] = useState(null);
    const [nODModelBrand, setNODModelBrand] = useState(null);
    const [nODModelMargin, setNODModelMargin] = useState(null);
    const [nODModelLast, setNODModelLast] = useState(null);

    const [nEquipRegion, setNEquipRegion] = useState(null);
    const [nEquipUnitTonnage, setNEquipUnitTonnage] = useState(null);
    const [nEquipAcHp, setNEquipAcHp] = useState(null);
    const [nEquipfrnAh, setNEquipfrnAh] = useState(null);
    const [nEquipBrand, setNEquipBrand] = useState(null);
    const [nEquipMargin, setNEquipMargin] = useState(null);
    const [nEquipLast, setNEquipLast] = useState(null);

    const [nCoilRegion, setNCoilRegion] = useState(null);
    const [nCoilUnitTonnage, setNCoilUnitTonnage] = useState(null);
    const [nCoilAcHp, setNCoilAcHp] = useState(null);
    const [nCoilfrnAh, setNCoilfrnAh] = useState(null);
    const [nCoilBrand, setNCoilBrand] = useState(null);
    const [nCoilMargin, setNCoilMargin] = useState(null);
    const [nCoilLast, setNCoilLast] = useState(null);

    const [coilRegion, setCoilRegion] = useState("N/A");
    const [coilUnitTonnage, setCoilUnitTonnage] = useState("4PX*BD36BS3");
    const [coilAcHp, setCoilAcHp] = useState("N/A");
    const [coilfrnAh, setCoilfrnAh] = useState("4PX*BU36BS3");
    const [coilBrand, setCoilBrand] = useState("4TXCB003DS3");
    const [coilMargin, setCoilMargin] = useState("4PX*BD30BS3");
    const [coilLast, setCoilLast] = useState("4TXCB003DS3");

    const [aHRIRegion, setAHRIRegion] = useState('N/A');
    const [aHRIUnitTonnage, setAHRIUnitTonnage] = useState('210791642');
    const [aHRIAcHp, setAHRIAcHp] = useState('N/A');
    const [aHRIfrnAh, setAHRIfrnAh] = useState('208777486');
    const [aHRIBrand, setAHRIBrand] = useState('211597914');
    const [aHRIMargin, setAHRIMargin] = useState('210649435');
    const [aHRILast, setAHRILast] = useState('211597913');

    const [equipCostRegion, setEquipCostRegion] = useState('');
    const [equipCostUnitTonnage, setEquipCostUnitTonnage] = useState('');
    const [equipCostAcHp, setEquipCostAcHp] = useState('');
    const [equipCostfrnAh, setEquipCostfrnAh] = useState('');
    const [equipCostBrand, setEquipCostBrand] = useState('');
    const [equipCostMargin, setEquipCostMargin] = useState('');
    const [equipCostLast, setEquipCostLast] = useState('');

    const [miscellaneousRegion, setMiscellaneousRegion] = useState('');
    const [miscellaneousUnitTonnage, setMiscellaneousUnitTonnage] = useState('');
    const [miscellaneousAcHp, setMiscellaneousAcHp] = useState('');
    const [miscellaneousfrnAh, setMiscellaneousfrnAh] = useState('');
    const [miscellaneousBrand, setMiscellaneousBrand] = useState('');
    const [miscellaneousMargin, setMiscellaneousMargin] = useState('');
    const [miscellaneousLast, setMiscellaneousLast] = useState('');

    const [lMCostRegion, setLMCostRegion] = useState('');
    const [lMCostUnitTonnage, setLMCostUnitTonnage] = useState('');
    const [lMCostAcHp, setLMCostAcHp] = useState('');
    const [lMCostfrnAh, setLMCostfrnAh] = useState('');
    const [lMCostBrand, setLMCostBrand] = useState('');
    const [lMCostMargin, setLMCostMargin] = useState('');
    const [lMCostLast, setLMCostLast] = useState('');

    const [marginDealerRegion, setmarginDealerRegion] = useState('$0');
    const [marginDealerUnitTonnage, setmarginDealerUnitTonnage] = useState('$0');
    const [marginDealerAcHp, setmarginDealerAcHp] = useState('$0');
    const [marginDealerfrnAh, setmarginDealerfrnAh] = useState('$0');
    const [marginDealerBrand, setmarginDealerBrand] = useState('$0');
    const [marginDealerMargin, setmarginDealerMargin] = useState('$0');
    const [marginDealerLast, setmarginDealerLast] = useState('$0');

    const [localeRebateRegion, setLocaleRebateRegion] = useState('');
    const [localeRebateUnitTonnage, setLocaleRebateUnitTonnage] = useState('');
    const [localeRebateAcHp, setLocaleRebateAcHp] = useState('');
    const [localeRebatefrnAh, setLocaleRebatefrnAh] = useState('');
    const [localeRebateBrand, setLocaleRebateBrand] = useState('');
    const [localeRebateMargin, setLocaleRebateMargin] = useState('');
    const [localeRebateLast, setLocaleRebateLast] = useState('');

    const [outDoorRegion, setOutDoorRegion] = useState('$0');
    const [outDoorUnitTonnage, setOutDoorUnitTonnage] = useState('$0');
    const [outDoorAcHp, setOutDoorAcHp] = useState('$0');
    const [outDoorfrnAh, setOutDoorfrnAh] = useState('$0');
    const [outDoorBrand, setOutDoorBrand] = useState('$0');
    const [outDoorMargin, setOutDoorMargin] = useState('$0');
    const [outDoorLast, setOutDoorLast] = useState('$0');

    const [furnaceRegion, setFurnaceRegion] = useState('$0');
    const [furnaceUnitTonnage, setFurnaceUnitTonnage] = useState('$0');
    const [furnaceAcHp, setFurnaceAcHp] = useState('$0');
    const [furnacefrnAh, setFurnacefrnAh] = useState('$0');
    const [furnaceBrand, setFurnaceBrand] = useState('$0');
    const [furnaceMargin, setFurnaceMargin] = useState('$0');
    const [furnaceLast, setFurnaceLast] = useState('$0');

    const [beforePriceRegion, setBeforePriceRegion] = useState('$0');
    const [beforePriceUnitTonnage, setBeforePriceUnitTonnage] = useState('$0');
    const [beforePriceAcHp, setBeforePriceAcHp] = useState('$0');
    const [beforePricefrnAh, setBeforePricefrnAh] = useState('$0');
    const [beforePriceBrand, setBeforePriceBrand] = useState('$0');
    const [beforePriceMargin, setBeforePriceMargin] = useState('$0');
    const [beforePriceLast, setBeforePriceLast] = useState('$0');

    const [beforeColorRegion, setBeforeColorRegion] = useState('#ffc000');
    const [beforeColorUnitTonnage, setBeforeColorUnitTonnage] = useState('#ffc000');
    const [beforeColorAcHp, setBeforeColorAcHp] = useState('#ffc000');
    const [beforeColorfrnAh, setBeforeColorfrnAh] = useState('#ffc000');
    const [beforeColorBrand, setBeforeColorBrand] = useState('#ffc000');
    const [beforeColorMargin, setBeforeColorMargin] = useState('#ffc000');
    const [beforeColorLast, setBeforeColorLast] = useState('#ffc000');

    const [afterColorRegion, setAfterColorRegion] = useState('#ffc000');
    const [afterColorUnitTonnage, setAfterColorUnitTonnage] = useState('#ffc000');
    const [afterColorAcHp, setAfterColorAcHp] = useState('#ffc000');
    const [afterColorfrnAh, setAfterColorfrnAh] = useState('#ffc000');
    const [afterColorBrand, setAfterColorBrand] = useState('#ffc000');
    const [afterColorMargin, setAfterColorMargin] = useState('#ffc000');
    const [afterColorLast, setAfterColorLast] = useState('#ffc000');

    const [marginDealerColorRegion, setMarginDealerColorRegion] = useState('#00b050');
    const [marginDealerColorUnitTonnage, setMarginDealerColorUnitTonnage] = useState('#00b050');
    const [marginDealerColorAcHp, setMarginDealerColorAcHp] = useState('#00b050');
    const [marginDealerColorfrnAh, setMarginDealerColorfrnAh] = useState('#00b050');
    const [marginDealerColorBrand, setMarginDealerColorBrand] = useState('#00b050');
    const [marginDealerColorMargin, setMarginDealerColorMargin] = useState('#00b050');
    const [marginDealerColorLast, setMarginDealerColorLast] = useState('#00b050');

    const [afterPriceRegion, setAfterPriceRegion] = useState('$0');
    const [afterPriceUnitTonnage, setAfterPriceUnitTonnage] = useState('-$2000');
    const [afterPriceAcHp, setAfterPriceAcHp] = useState('-$2000');
    const [afterPricefrnAh, setAfterPricefrnAh] = useState('-$2000');
    const [afterPriceBrand, setAfterPriceBrand] = useState('-$2000');
    const [afterPriceMargin, setAfterPriceMargin] = useState('-$2000');
    const [afterPriceLast, setAfterPriceLast] = useState('-$2000');

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const firstTableResponse = await getFirstTableData();
                setFirstTData(firstTableResponse);
                setSecondTData(firstTableResponse);
                handleMainBrand('TRANE', true)
                lookupTableApiCall('TRANE')
                setLoading(false);
                return { firstTableResponse };
            } catch (error) {
                console.error('Error fetching data for tables:', error);
                throw error;
            }
        };
        const data = fetchData();
    }, []);

    const secondApiCall = (region = mainRegion, Unit_Tonnage = mainUnitTonnage, Ac_Hp = mainAcHp, Frn_Ah = mainfrnAh, Brand = mainBrand, Desired_Gross_Margin = mainMargin, f1 = furnaceRegion, f2 = furnaceUnitTonnage, f3 = furnaceAcHp, f4 = furnacefrnAh, f5 = furnaceBrand, f6 = furnaceMargin, f7 = furnaceLast, firstTime = false) => {
        handleLoadOpen();
        const secondPayload = {
            "Region": region,
            "Unit_Tonnage": Unit_Tonnage,
            "Ac_Hp": Ac_Hp,
            "Frn_Ah": Frn_Ah,
            "Brand": Brand,
            "Desired_Gross_Margin": Desired_Gross_Margin
        }
        const fetchData = async () => {
            try {
                const secondTableResponse = await secondTableDataApi(secondPayload);
                let row7 = secondTableResponse.Product_Family;
                let row8 = secondTableResponse.Od_Model;
                setSecondTData(secondTableResponse)
                setProductRegion(row7.Region.length > 0 ? row7.Region[0] : []);
                setProductUnitTonnage(row7.Unit_Tonnage.length > 0 ? row7.Unit_Tonnage[0] : []);
                setProductAcHp(row7.Ac_Hp.length > 0 ? firstTime ? row7.Ac_Hp[1] : row7.Ac_Hp[0] : []);
                setProductfrnAh(row7.Frn_Ah.length > 0 ? firstTime ? row7.Frn_Ah[2] : row7.Frn_Ah[0] : []);
                setProductBrand(row7.Brand.length > 0 ? row7.Brand[0] : []);
                setProductMargin(row7.Desired_Gross_Margin.length > 0 ? row7.Desired_Gross_Margin[0] : []);
                setProductLast(row7.Last_Column.length > 0 ? row7.Last_Column[0] : []);
                console.log('current od row: ', row8);
                filterProductFamily(row8, row7, region, Ac_Hp, Frn_Ah, Brand, 'all', '', f1, f2, f3, f4, f5, f6, f7, "$0", "$0", "$0", "$0","$0", firstTime);
                if (row7?.Region[0] == 'N/A') {
                    setEquipCostRegion("$0");
                    setLMCostRegion("$0");
                    setmarginDealerRegion("$0");
                    setLocaleRebateRegion("$0");
                    setAfterPriceRegion("$0");
                    setBeforePriceRegion("$0")
                    filterProductFamily(row8, 'N/A', region, Ac_Hp, Frn_Ah, Brand, 'region', f1,f1, f2, f3, f4, f5, f6, f7, "$0", "$0", "$0", "$0","$0");
                    UpdateBgColor("$0", beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
                    UpdateBgColor("$0", afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                    UpdateBgColor("$0", marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')

                }
                if (row7?.Unit_Tonnage[0] == 'N/A') {
                    setEquipCostUnitTonnage("$0");
                    setLMCostUnitTonnage("$0");
                    setmarginDealerUnitTonnage("$0");
                    setLocaleRebateUnitTonnage("$0");
                    setAfterPriceUnitTonnage("$0");
                    setBeforePriceUnitTonnage("$0")
                    filterProductFamily(row8, 'N/A', region, Ac_Hp, Frn_Ah, Brand, 'unitTonnage', f2, f1, f2, f3, f4, f5, f6, f7, "$0", "$0", "$0", "$0","$0");
                    UpdateBgColor(beforePriceRegion, "$0", beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
                    UpdateBgColor(afterPriceRegion, "$0", afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                    UpdateBgColor(marginDealerRegion, "$0", marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
                }
                if (row7?.Ac_Hp[0] == 'N/A') {
                    setEquipCostAcHp("$0");
                    setLMCostAcHp("$0");
                    setmarginDealerAcHp("$0");
                    setLocaleRebateAcHp("$0");
                    setAfterPriceAcHp("$0");
                    setBeforePriceAcHp("$0")
                    filterProductFamily(row8, 'N/A', region, Ac_Hp, Frn_Ah, Brand, 'ac_hp', f3, f1, f2, f3, f4, f5, f6, f7, "$0", "$0", "$0", "$0","$0");
                    UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, "$0", beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
                    UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, "$0", afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                    UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, "$0", marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
                }
                if (row7?.Frn_Ah[0] == 'N/A') {
                    setEquipCostfrnAh("$0");
                    setLMCostfrnAh("$0");
                    setmarginDealerfrnAh("$0");
                    setLocaleRebatefrnAh("$0");
                    setAfterPricefrnAh("$0");
                    setBeforePricefrnAh("$0")
                    filterProductFamily(row8, 'N/A', region, Ac_Hp, Frn_Ah, Brand, 'frn_ah', f4,f1, f2, f3, f4, f5, f6, f7,"$0", "$0", "$0", "$0","$0");
                    UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, "$0", beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
                    UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, "$0", afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                    UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, "$0", marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')


                }
                if (row7?.Brand[0] == 'N/A') {
                    setEquipCostBrand("$0");
                    setLMCostBrand("$0");
                    setmarginDealerBrand("$0");
                    setLocaleRebateBrand("$0");
                    setAfterPriceBrand("$0");
                    setBeforePriceBrand("$0")
                    filterProductFamily(row8, 'N/A', region, Ac_Hp, Frn_Ah, Brand, 'brand', f5, f1, f2, f3, f4, f5, f6, f7, "$0", "$0", "$0", "$0","$0");
                    UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, "$0", beforePriceMargin, beforePriceLast, 'before')
                    UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, "$0", afterPriceMargin, afterPriceLast, 'after')
                    UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, "$0", marginDealerMargin, marginDealerLast, 'dealer')


                }
                if (row7?.Desired_Gross_Margin[0] == 'N/A') {
                    setEquipCostMargin("$0");
                    setLMCostMargin("$0");
                    setmarginDealerMargin("$0");
                    setLocaleRebateMargin("$0");
                    setAfterPriceMargin("$0");
                    setBeforePriceMargin("$0")
                    filterProductFamily(row8, 'N/A', region, Ac_Hp, Frn_Ah, Brand, 'margin', f6, f1, f2, f3, f4, f5, f6, f7, "$0", "$0", "$0", "$0","$0");
                    UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, "$0", beforePriceLast, 'before')
                    UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, "$0", afterPriceLast, 'after')
                    UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, "$0", marginDealerLast, 'dealer')


                }
                if (row7?.Last_Column[0] == 'N/A') {
                    setEquipCostLast("$0");
                    setLMCostLast("$0");
                    setmarginDealerLast("$0");
                    setLocaleRebateLast("$0");
                    setAfterPriceLast("$0");
                    setBeforePriceLast("$0")
                    filterProductFamily(row8, 'N/A', region, Ac_Hp, Frn_Ah, Brand, 'last', f7, f1, f2, f3, f4, f5, f6, f7, "$0", "$0", "$0", "$0","$0");
                    UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, "$0", 'before')
                    UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, "$0", 'after')
                    UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, "$0", 'dealer')

                }
                return { secondTableResponse };
            } catch (error) {
                console.error('Error fetching data for tables:', error);
                throw error;
            }
        }
        const data = fetchData();
    }

    const filterProductFamily = (row8, row7, region = mainRegion, Ac_Hp = mainAcHp, Frn_Ah = mainfrnAh, Brand = mainBrand, col, furnace, f1 = furnaceRegion, f2 = furnaceUnitTonnage, f3 = furnaceAcHp, f4 = furnacefrnAh, f5 = furnaceBrand, f6 = furnaceMargin, f7 = furnaceLast, equipCost = '', lMCOst = '', mDealer = '', local = '', misc='',firstTime = false) => {
        const filterOdmodel = (arr, match) => {
            return arr.filter(x => {
                return (x.slice(0, 4) == match.slice(0, 4) || x == 'N/A');
            })
        }
        console.log('row7: ', row7, row8)
        //For product fillter
        let odRegion;
        let odUnitTonnage;
        let odAcHp;
        let odFrnAh;
        let odBrand;
        let odMargin;
        let odLast;
        switch (col) {
            case 'region':
                odRegion = filterOdmodel(row8?.Region?.length > 0 ? row8.Region : [], row7)
                setOdFilterDataRegion(odRegion);
                setODModelRegion(odRegion.length > 0 ? odRegion[0] : []);
                thirdODModelApiCall(odRegion.length > 0 ? odRegion[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'region', furnace, equipCost, lMCOst, mDealer, local,misc);
                break;
            case 'unitTonnage':
                odUnitTonnage = filterOdmodel(row8?.Unit_Tonnage?.length > 0 ? row8.Unit_Tonnage : [], row7)
                setOdFilterDataUnitTonnage(odUnitTonnage)
                setODModelUnitTonnage(odUnitTonnage.length > 0 ? odUnitTonnage[0] : []);
                thirdODModelApiCall(odUnitTonnage.length > 0 ? odUnitTonnage[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'unitTonnage', furnace, equipCost, lMCOst, mDealer, local,misc);
                break;
            case 'ac_hp':
                odAcHp = filterOdmodel(row8?.Ac_Hp?.length > 0 ? row8.Ac_Hp : [], row7)
                setOdFilterDataAcHP(odAcHp)
                setODModelAcHp(odAcHp.length > 0 ? odAcHp[0] : []);
                thirdODModelApiCall(odAcHp.length > 0 ? odAcHp[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'ac_hp', furnace, equipCost, lMCOst, mDealer, local,misc);
                break;
            case 'frn_ah':
                odFrnAh = filterOdmodel(row8?.Frn_Ah?.length > 0 ? row8.Frn_Ah : [], row7)
                setOdFilterDataFrnAh(odFrnAh)
                setODModelfrnAh(odFrnAh.length > 0 ? odFrnAh[0] : []);
                thirdODModelApiCall(odFrnAh.length > 0 ? odFrnAh[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'frn_ah', furnace, equipCost, lMCOst, mDealer, local,misc);
                break;
            case 'brand':
                odBrand = filterOdmodel(row8?.Brand?.length > 0 ? row8.Brand : [], row7)
                setOdFilterDataBrand(odBrand)
                setODModelBrand(odBrand.length > 0 ? odBrand[0] : []);
                thirdODModelApiCall(odBrand.length > 0 ? odBrand[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'brand', furnace, equipCost, lMCOst, mDealer, local,misc);
                break;
            case 'margin':
                odMargin = filterOdmodel(row8?.Desired_Gross_Margin?.length > 0 ? row8.Desired_Gross_Margin : [], row7)
                setOdFilterDataMargin(odMargin)
                setODModelMargin(odMargin.length > 0 ? odMargin[0] : []);
                thirdODModelApiCall(odMargin.length > 0 ? odMargin[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'margin', furnace, equipCost, lMCOst, mDealer, local,misc);
                break;
            case 'last':
                odLast = filterOdmodel(row8?.Last_Column?.length > 0 ? row8.Last_Column : [], row7)
                setOdFilterDataLast(odLast)
                setODModelLast(odLast.length > 0 ? odLast[0] : []);
                thirdODModelApiCall(odLast.length > 0 ? odLast[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'last', furnace, equipCost, lMCOst, mDealer, local,misc);
                break;
            case 'all':
                odRegion = filterOdmodel(row8?.Region?.length > 0 ? row8.Region : [], row7?.Region?.length > 0 ? row7?.Region[0] : '')
                odUnitTonnage = filterOdmodel(row8?.Unit_Tonnage?.length > 0 ? row8.Unit_Tonnage : [], row7?.Unit_Tonnage?.length > 0 ? row7?.Unit_Tonnage[0] : '')
                odAcHp = filterOdmodel(row8?.Ac_Hp?.length > 0 ? row8.Ac_Hp : [], row7?.Ac_Hp?.length > 0 ? firstTime ? "4TWR6" : row7?.Ac_Hp[0] : '')
                odFrnAh = filterOdmodel(row8?.Frn_Ah?.length > 0 ? row8.Frn_Ah : [], row7?.Frn_Ah?.length > 0 ? firstTime ? "4TWX7" : row7?.Frn_Ah[0] : '')
                odBrand = filterOdmodel(row8?.Brand?.length > 0 ? row8.Brand : [], row7?.Brand?.length > 0 ? row7?.Brand[0] : '')
                odMargin = filterOdmodel(row8?.Desired_Gross_Margin?.length > 0 ? row8.Desired_Gross_Margin : [], row7?.Desired_Gross_Margin?.length > 0 ? row7?.Desired_Gross_Margin[0] : '')
                odLast = filterOdmodel(row8?.Last_Column?.length > 0 ? row8.Last_Column : [], row7?.Last_Column?.length > 0 ? row7?.Last_Column[0] : '')
                setOdFilterDataRegion(odRegion);
                setOdFilterDataUnitTonnage(odUnitTonnage)
                setOdFilterDataAcHP(odAcHp)
                setOdFilterDataFrnAh(odFrnAh)
                setOdFilterDataBrand(odBrand)
                setOdFilterDataMargin(odMargin)
                setOdFilterDataLast(odLast)

                console.log('current od m odels: ', 'r:',odRegion,' U: ',odUnitTonnage,' a: ',odAcHp, ' f: ',odFrnAh, ' b: ', odBrand, ' m: ',odMargin, ' L: ', odLast)

                setODModelRegion(odRegion.length > 0 ? odRegion[0] : []);
                setODModelUnitTonnage(odUnitTonnage.length > 0 ? odUnitTonnage[0] : []);
                setODModelAcHp(odAcHp.length > 0 ? firstTime ? odAcHp[1] : odAcHp[0] : []);
                setODModelfrnAh(odFrnAh.length > 0 ? odFrnAh[0] : []);
                setODModelBrand(odBrand.length > 0 ? firstTime ? odBrand[1] : odBrand[0] : []);
                setODModelMargin(odMargin.length > 0 ? odMargin[0] : []);
                setODModelLast(odLast.length > 0 ? firstTime ? odLast[1] : odLast[0] : []);

                thirdODModelApiCall(odRegion.length > 0 ? odRegion[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'region', f1, equipCostRegion, lMCostRegion, marginDealerRegion, localeRebateRegion,miscellaneousRegion);
                thirdODModelApiCall(odUnitTonnage.length > 0 ? odUnitTonnage[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'unitTonnage', f2, equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, localeRebateUnitTonnage,miscellaneousUnitTonnage);
                thirdODModelApiCall(odAcHp.length > 0 ? firstTime ? odAcHp[1] : odAcHp[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'ac_hp', f3, equipCostAcHp, lMCostAcHp, marginDealerAcHp, localeRebateAcHp,miscellaneousAcHp);
                thirdODModelApiCall(row8.Frn_Ah.length > 0 ? odFrnAh[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'frn_ah', f4, equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, localeRebatefrnAh,miscellaneousfrnAh);
                thirdODModelApiCall(row8.Brand.length > 0 ? firstTime ? odBrand[1] : row8.Brand[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'brand', f5, equipCostBrand, lMCostBrand, marginDealerBrand, localeRebateBrand,miscellaneousBrand);
                thirdODModelApiCall(odMargin.length > 0 ? odMargin[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'margin', f6, equipCostMargin, lMCostMargin, marginDealerMargin, localeRebateMargin,miscellaneousMargin);
                thirdODModelApiCall(odLast.length > 0 ? firstTime ? odLast[1] : odLast[0] : [], '', '', region, Ac_Hp, Frn_Ah, Brand, "ODModel", 'last', f7, equipCostLast, lMCostLast, marginDealerLast, localeRebateLast,miscellaneousLast);
                break;
        }

    }
    const thirdODModelApiCall = (OdModel = '', QEquip = '', QCoil = '', region = mainRegion, Ac_Hp = mainAcHp, Frn_Ah = mainfrnAh, Brand = mainBrand, type = "OdModel", col = '', furnace = '', equipCost, lMCOst, mDealer, local,misc='') => {
        handleLoadOpen();
        const ModelPayload = {
            "RequestType": type,
            "Od_Model": OdModel,
            "Brand_Name": Brand,
            "Frn_Ah": Frn_Ah,
            "Ac_Hp": Ac_Hp,
            "Region": region,
            "Qualifying_Id_Equipment": QEquip,
            "Qualifying_Coil": QCoil,
        }
        const fetchData = async () => {
            try {
                const ModelTableResponse = await onChangeTableDataApi(ModelPayload);
                let od = ModelTableResponse.Qualifying_Ratings_with_OD;
                let equip = ModelTableResponse.Qualifying_Indoor_Equipment;
                let coil = ModelTableResponse.Qualifying_Coil;
                let ahri = ModelTableResponse.AHRI_Certification_Number;
                if (od && (equip?.length > 0 || equip)) {
                    switch (col) {
                        case 'region': setEquipRegion(equip[0]);
                            updateFurnace(lookUpValidate, equip[0], 'region',misc);

                            setLookUpRegion(equip)
                            setQRODRegion(od);
                            setOdTDataRegion(ModelTableResponse);
                            thirdODModelApiCall(OdModel ? OdModel : od, QEquip ? QEquip : equip[0], '', region, Ac_Hp, Frn_Ah, Brand, "Qualifying_Coil", 'region', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'unitTonnage': setEquipUnitTonnage(equip[0]);
                            updateFurnace(lookUpValidate, equip[0], 'unitTonnage',misc);
                            setLookUpUnitTonnage(equip)

                            setQRODUnitTonnage(od);
                            setOdTDataUnitTonnage(ModelTableResponse);
                            thirdODModelApiCall(OdModel ? OdModel : od, QEquip ? QEquip : equip[0], '', region, Ac_Hp, Frn_Ah, Brand, "Qualifying_Coil", 'unitTonnage', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'ac_hp': setEquipAcHp(equip[0]);
                            updateFurnace(lookUpValidate, equip[0], 'ac_hp',misc);
                            setLookUpAcHP(equip);
                            setQRODAcHp(od);
                            setOdTDataAcHP(ModelTableResponse);
                            thirdODModelApiCall(OdModel ? OdModel : od, QEquip ? QEquip : equip[0], '', region, Ac_Hp, Frn_Ah, Brand, "Qualifying_Coil", 'ac_hp', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'frn_ah': setEquipfrnAh(equip[0]);
                            updateFurnace(lookUpValidate, equip[0], 'frn_ah',misc);
                            setLookUpFrnAh(equip);
                            setQRODfrnAh(od);
                            setOdTDataFrnAh(ModelTableResponse);
                            thirdODModelApiCall(OdModel ? OdModel : od, QEquip ? QEquip : equip[0], '', region, Ac_Hp, Frn_Ah, Brand, "Qualifying_Coil", 'frn_ah', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'brand': setEquipBrand(equip[0]);
                            updateFurnace(lookUpValidate, equip[0], 'brand',misc);
                            setLookUpBrand(equip);
                            setQRODBrand(od);
                            setOdTDataBrand(ModelTableResponse);
                            thirdODModelApiCall(OdModel ? OdModel : od, QEquip ? QEquip : equip[0], '', region, Ac_Hp, Frn_Ah, Brand, "Qualifying_Coil", 'brand', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'margin': setEquipMargin(equip[0]);
                            updateFurnace(lookUpValidate, equip[0], 'margin',misc);
                            setLookUpMargin(equip);
                            setQRODMargin(od);
                            setOdTDataMargin(ModelTableResponse);
                            thirdODModelApiCall(OdModel ? OdModel : od, QEquip ? QEquip : equip[0], '', region, Ac_Hp, Frn_Ah, Brand, "Qualifying_Coil", 'margin', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'last': setEquipLast(equip[0]);
                            updateFurnace(lookUpValidate, equip[0], 'last',misc);
                            setLookUpLast(equip);
                            setQRODLast(od);
                            setOdTDataLast(ModelTableResponse);
                            thirdODModelApiCall(OdModel ? OdModel : od, QEquip ? QEquip : equip[0], '', region, Ac_Hp, Frn_Ah, Brand, "Qualifying_Coil", 'last', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                    }
                }
                if (coil?.length > 0) {
                    switch (col) {
                        case 'region': setCoilRegion(coil[0]);
                            setCoilTDataRegion(ModelTableResponse);
                            thirdODModelApiCall(OdModel, QEquip ? QEquip : equip[0], QCoil ? QCoil : coil[0], region, Ac_Hp, Frn_Ah, Brand, "Ahri", 'region', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'unitTonnage': setCoilUnitTonnage(coil[0]);
                            setCoilTDataUnitTonnage(ModelTableResponse);
                            thirdODModelApiCall(OdModel, QEquip ? QEquip : equip[0], QCoil ? QCoil : coil[0], region, Ac_Hp, Frn_Ah, Brand, "Ahri", 'unitTonnage', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'ac_hp': setCoilAcHp(coil[0]);
                            setCoilTDataAcHP(ModelTableResponse);
                            thirdODModelApiCall(OdModel, QEquip ? QEquip : equip[0], QCoil ? QCoil : coil[0], region, Ac_Hp, Frn_Ah, Brand, "Ahri", 'ac_hp', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'frn_ah': setCoilfrnAh(coil[0]);
                            setCoilTDataFrnAh(ModelTableResponse);
                            thirdODModelApiCall(OdModel, QEquip ? QEquip : equip[0], QCoil ? QCoil : coil[0], region, Ac_Hp, Frn_Ah, Brand, "Ahri", 'frn_ah', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'brand': setCoilBrand(coil[0]);
                            setCoilTDataBrand(ModelTableResponse);
                            thirdODModelApiCall(OdModel, QEquip ? QEquip : equip[0], QCoil ? QCoil : coil[0], region, Ac_Hp, Frn_Ah, Brand, "Ahri", 'brand', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'margin': setCoilMargin(coil[0]);
                            setCoilTDataMargin(ModelTableResponse);
                            thirdODModelApiCall(OdModel, QEquip ? QEquip : equip[0], QCoil ? QCoil : coil[0], region, Ac_Hp, Frn_Ah, Brand, "Ahri", 'margin', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                        case 'last': setCoilLast(coil[0]);
                            setCoilTDataLast(ModelTableResponse);
                            thirdODModelApiCall(OdModel, QEquip ? QEquip : equip[0], QCoil ? QCoil : coil[0], region, Ac_Hp, Frn_Ah, Brand, "Ahri", 'last', furnace, equipCost, lMCOst, mDealer, local,misc)
                            break;
                    }
                }
                if (ahri) {
                    switch (col) {
                        case 'region': setAHRIRegion(ahri);
                            updateOutDoor(ahri, Ac_Hp, 'region', furnace, equipCost, lMCOst, mDealer, local,misc);
                            setAhriTDataRegion(ModelTableResponse);
                            break;
                        case 'unitTonnage': setAHRIUnitTonnage(ahri);
                            updateOutDoor(ahri, Ac_Hp, 'unitTonnage', furnace, equipCost, lMCOst, mDealer, local,misc);
                            setAhriTDataUnitTonnage(ModelTableResponse);
                            break;
                        case 'ac_hp': setAHRIAcHp(ahri);
                            updateOutDoor(ahri, Ac_Hp, 'ac_hp', furnace, equipCost, lMCOst, mDealer, local,misc);
                            setAhriTDataAcHP(ModelTableResponse);
                            break;
                        case 'frn_ah': setAHRIfrnAh(ahri);
                            updateOutDoor(ahri, Ac_Hp, 'frn_ah', furnace, equipCost, lMCOst, mDealer, local,misc);
                            setAhriTDataFrnAh(ModelTableResponse);
                            break;
                        case 'brand': setAHRIBrand(ahri);
                            updateOutDoor(ahri, Ac_Hp, 'brand', furnace, equipCost, lMCOst, mDealer, local,misc);
                            setAhriTDataBrand(ModelTableResponse);
                            break;
                        case 'margin': setAHRIMargin(ahri);
                            updateOutDoor(ahri, Ac_Hp, 'margin', furnace, equipCost, lMCOst, mDealer, local,misc);
                            setAhriTDataMargin(ModelTableResponse);
                            break;
                        case 'last': setAHRILast(ahri);
                            updateOutDoor(ahri, Ac_Hp, 'last', furnace, equipCost, lMCOst, mDealer, local,misc);
                            setAhriTDataLast(ModelTableResponse);
                            break;
                    }
                }
                if (OdModel == 'N/A' || equip[0] == 'N/A') {
                   
                    ModelTableResponse.Qualifying_Coil = ['N/A'];
                    switch (col) {
                        case 'region':
                            setAHRIRegion('N/A');
                            setCoilRegion(['N/A']);
                            setEquipRegion(['N/A']);
                            setQRODRegion(od == '' ? 0 : od);
                            setOdTDataRegion(ModelTableResponse);
                            setCoilTDataRegion(ModelTableResponse);
                            setAhriTDataRegion(ModelTableResponse);
                            updateOutDoor(0, Ac_Hp, 'region', furnace, equipCost, lMCOst, mDealer, local,misc);
                            break;
                        case 'unitTonnage':
                            setAHRIUnitTonnage('N/A');
                            setCoilUnitTonnage(['N/A']);
                            setEquipUnitTonnage(['N/A']);
                            setQRODUnitTonnage(od == '' ? 0 : od);
                            setOdTDataUnitTonnage(ModelTableResponse);
                            setCoilTDataUnitTonnage(ModelTableResponse);
                            setAhriTDataUnitTonnage(ModelTableResponse);
                            updateOutDoor(0, Ac_Hp, 'unitTonnage', furnace, equipCost, lMCOst, mDealer, local,misc);
                            break;
                        case 'ac_hp':
                            setAHRIAcHp('N/A');
                            setCoilAcHp(['N/A']);
                            setEquipAcHp(['N/A']);
                            setQRODAcHp(od == '' ? 0 : od);
                            setOdTDataAcHP(ModelTableResponse);
                            setCoilTDataAcHP(ModelTableResponse);
                            setAhriTDataAcHP(ModelTableResponse);
                            updateOutDoor(0, Ac_Hp, 'ac_hp', furnace, equipCost, lMCOst, mDealer, local,misc);
                            break;
                        case 'frn_ah':
                            setAHRIfrnAh('N/A');
                            setCoilfrnAh(['N/A']);
                            setEquipfrnAh(['N/A']);
                            setQRODfrnAh(od == '' ? 0 : od);
                            setOdTDataFrnAh(ModelTableResponse);
                            setCoilTDataFrnAh(ModelTableResponse);
                            setAhriTDataFrnAh(ModelTableResponse);
                            updateOutDoor(0, Ac_Hp, 'frn_ah', furnace, equipCost, lMCOst, mDealer, local,misc);
                            break;
                        case 'brand':
                            setAHRIBrand('N/A');
                            setCoilBrand(['N/A']);
                            setEquipBrand(['N/A']);
                            setQRODBrand(od == '' ? 0 : od);
                            setOdTDataBrand(ModelTableResponse);
                            setCoilTDataBrand(ModelTableResponse);
                            setAhriTDataBrand(ModelTableResponse);
                            updateOutDoor(0, Ac_Hp, 'brand', furnace, equipCost, lMCOst, mDealer, local,misc);
                            break;
                        case 'margin':
                            setAHRIMargin('N/A');
                            setCoilMargin(['N/A']);
                            setEquipMargin(['N/A']);
                            setQRODMargin(od == '' ? 0 : od);
                            setOdTDataMargin(ModelTableResponse);
                            setCoilTDataMargin(ModelTableResponse);
                            setAhriTDataMargin(ModelTableResponse);
                            updateOutDoor(0, Ac_Hp, 'margin', furnace, equipCost, lMCOst, mDealer, local,misc);
                            break;
                        case 'last':
                            setAHRILast('N/A');
                            setCoilLast(['N/A']);
                            setEquipLast(['N/A']);
                            setQRODLast(od == '' ? 0 : od);
                            setOdTDataLast(ModelTableResponse);
                            setCoilTDataLast(ModelTableResponse);
                            setAhriTDataLast(ModelTableResponse);
                            updateOutDoor(0, Ac_Hp, 'last', furnace, equipCost, lMCOst, mDealer, local,misc);
                            break;
                    }
                }
            } catch (error) {
                console.error('Error fetching data for tables:', error);
                throw error;
            }
            handleLoadClose();
        }
        const data = fetchData();
    }
    const lookupTableApiCall = (Brand = mainBrand) => {
        const lookUpPayload = {
            "Brand_Name": Brand,
        }
        const fetchData = async () => {
            try {
                const lookupTableResponse = await lookTableDataApi(lookUpPayload);
                setLookUpValidate(lookupTableResponse)
                return lookupTableResponse;
            } catch (error) {
                console.error('Error fetching data for tables:', error);
                throw error;
            }
        }
        const data = fetchData();

    }
    const staticTableData = [
        ['Region', 'SEER2', 'EER2', 'HSPF2', 'COP @ 5°', 'Cap Ratio'],
        ['North', '≥15.2', '≥10', '≥8.1', '≥1.75', '≥58% (17°F/47°F) or ≥70% (5°F/47°F)'],
        ['South', '≥15.2', '≥11.7', '≥7.8', 'N/A', 'N/A'],
    ];

    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB');
    console.log('current date123: ', formattedDate);
    const inputTableData = [
        ['Main User Input'],
        ['Data Updated', 'Region', 'Unit Tonnage', 'AC/HP', 'FRN/AH', 'Brand', 'Desired Gross Margin %', ''],
        [formattedDate, ["South", "North"], [2, 1, 3], ["HP", "AC"], ["FRN", "AH"], ["TRANE", "RUNTRU"], ["30%", "50%", "100%"]],
        ['SEER2 Family', [14], [15], [16], [17], [18], [19], [20]],
        ['Product Family', ['4TWR4'], ['4TWR5'], ['4TWR6'], [''], ['4TWV8'], ['4TWL9'], ['4TWV0']],
        ['OD Model', ['4TWR4024N1000A'], ['4TWR5024N1000A'], ['4TWR6024N1000A'], [''], ['4TWV8X24A1000A'], ['4TWL9024A1000A'], ['4TWV0X24A1000A']],
        ['# of Qualifying Ratings with OD', [0], [49], [215], [0], [65], [93], [65]],
        ['Qualifying Indoor Equipment', ['N/A'], ['*UHMB060ACV3'], ['*UHMB080ACV3'], [''], ['*UHMB080ACV3'], ['L8V1A060U3VSA+RP'], ['*UHMB080ACV3']],
        ['Non Qualifying Indoor Equipment', [''], [''], [''], [''], [''], [''], ['']],
        ['Qualifying Coil (If Applicable)', ['N/A'], [''], ['4PX*BU36BS3'], [''], ['4PX*BU36BS3'], ['4PX*BU30BS3'], ['4PX*BU36BS3']],
        ['Non Qualifying Coil (If Applicable)', [''], [''], [''], [''], [''], [''], ['']],
        ['AHRI Certification Number', ['N/A'], ['N/A'], [208775939], [''], [207526234], [210653261], [207558946]],

    ];


    function formatCurrencyNumberWithDecimal(value) {
        if (value) {
            let cleanedValue = value.replace(/,/g, ''); // Remove existing commas
            let negative = false;
            // Check if value is negative
            if (cleanedValue.startsWith('-')) {
                negative = true;
                cleanedValue = cleanedValue.slice(1); // Remove the negative sign temporarily
            }
            const parts = cleanedValue.split('.');
            const integerPart = parts[0].replace(/\D/g, ''); // Remove non-digits
            const decimalPart = parts[1] ? parts[1].replace(/\D/g, '').slice(0, 4) : '';
            let formattedValue = '';
            if (integerPart) {

                formattedValue = "$" + parseFloat(integerPart).toLocaleString('en-US');
            }
            if (decimalPart) {
                formattedValue += `.${decimalPart}`;
            }
            // Add back the negative sign if necessary
            if (negative) {
                formattedValue = '-' + formattedValue;
            }
            return formattedValue;
        }
        return ('$' + value); // Return an empty string to avoid interfering with input value
    }
    function formatMarignData(value) {
        if (value) {
            let cleanedValue = value.replace(/,/g, ''); // Remove existing commas
            let negative = false;
            // Check if value is negative
            if (cleanedValue.startsWith('-')) {
                negative = true;
                cleanedValue = cleanedValue.slice(1); // Remove the negative sign temporarily
            }
            const parts = cleanedValue.split('.');
            const integerPart = parts[0].replace(/\D/g, ''); // Remove non-digits
            const decimalPart = parts[1] ? parts[1].replace(/\D/g, '').slice(0, 4) : '';
            let formattedValue = '0';
            if (integerPart) {

                formattedValue = parseFloat(integerPart).toLocaleString('en-US') + '%';
            }
            if (decimalPart) {
                formattedValue += `.${decimalPart}`;
            }
            // Add back the negative sign if necessary
            return formattedValue;
        }
        return (value = '' ? 0 + "%" : value + "%"); // Return an empty string to avoid interfering with input value
    }

    const handleMainRegion = (val) => {
        setMainRegion(val);
        setupFuntion(val,"region");
        return val;
    }
    const handleMainUnitTonnage = (val) => {
        setMainUnitTonnage(val);
        setupFuntion(val,"unitTonnage");
        return val;
    }
    const handleMainAcHp = (val) => {
        setMainAcHp(val);
        setupFuntion(val,"ac_hp");
        return val;
    }
    const handleMainFrnAh = (val) => {
        setMainfrnAh(val);
        setupFuntion(val,"frn_ah");
        return val;
    }
    const handleMainBrand = (val, firstTime) => {
        setMainBrand(val);
        lookupTableApiCall(val)
        setupFuntion(val,"brand",firstTime);
        return val;
    }
    const setupFuntion=(val, col,firstTime=false)=>{
        let aachp = col =="ac_hp"? val: mainAcHp;
        let resReg= updateFurnace(lookUpValidate, equipRegion[0], 'region',miscellaneousRegion);
        let resUnit= updateFurnace(lookUpValidate, equipUnitTonnage[0], 'unitTonnage',miscellaneousUnitTonnage);
        let resAchp= updateFurnace(lookUpValidate, equipAcHp[0], 'ac_hp',miscellaneousAcHp);
        let resFrn= updateFurnace(lookUpValidate, equipfrnAh[0], 'frn_ah',miscellaneousfrnAh);
        let resBrand= updateFurnace(lookUpValidate, equipBrand[0], 'brand',miscellaneousBrand);
        let resMar= updateFurnace(lookUpValidate, equipMargin[0], 'margin',miscellaneousMargin);
        let resLast= updateFurnace(lookUpValidate, equipLast[0], 'last',miscellaneousLast);
        updateOutDoor(aHRIRegion, aachp, 'region', resReg, equipCostRegion, lMCostRegion, marginDealerRegion, localeRebateRegion,miscellaneousRegion);
        updateOutDoor(aHRIUnitTonnage, aachp, 'unitTonnage', resUnit, equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, localeRebateUnitTonnage,miscellaneousUnitTonnage);
        updateOutDoor(aHRIAcHp,aachp, 'ac_hp', resAchp, equipCostAcHp, lMCostAcHp, marginDealerAcHp, localeRebateAcHp,miscellaneousAcHp);
        updateOutDoor(aHRIfrnAh, aachp, 'frn_ah', resFrn, equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, localeRebatefrnAh,miscellaneousfrnAh);
        updateOutDoor(aHRIBrand, aachp, 'brand', resBrand, equipCostBrand, lMCostBrand, marginDealerBrand, localeRebateBrand,miscellaneousBrand);
        updateOutDoor(aHRIMargin, aachp, 'margin', resMar, equipCostMargin, lMCostMargin, marginDealerMargin, localeRebateMargin,miscellaneousMargin);
        updateOutDoor(aHRILast, aachp, 'last', resLast, equipCostLast, lMCostLast, marginDealerLast, localeRebateLast,miscellaneousLast);
        switch (col) {
            case 'region':
                 secondApiCall(val, mainUnitTonnage, mainAcHp, mainfrnAh, mainBrand, mainMargin,resReg, resUnit, resAchp, resFrn, resBrand, resMar, resLast);
                 break;
                 case 'unitTonnage':
                     secondApiCall(mainRegion, val, mainAcHp, mainfrnAh, mainBrand, mainMargin,resReg, resUnit, resAchp, resFrn, resBrand, resMar, resLast);
                     
                     break;
                     case 'ac_hp':
                         secondApiCall(mainRegion, mainUnitTonnage, val, mainfrnAh, mainBrand, mainMargin,resReg, resUnit, resAchp, resFrn, resBrand, resMar, resLast);
                         
                         break;
                         case 'frn_ah':
                             secondApiCall(mainRegion, mainUnitTonnage, mainAcHp, val, mainBrand, mainMargin,resReg, resUnit, resAchp, resFrn, resBrand, resMar, resLast);
                             break;
                             case 'brand':
                    secondApiCall(mainRegion, mainUnitTonnage, mainAcHp, mainfrnAh, val, mainMargin,resReg, resUnit, resAchp, resFrn, resBrand, resMar, resLast, firstTime);
                    
                    break;
        }
    }
    const handleMainMargin = (val) => {
        let updated = formatMarignData(val)

        setMainMargin(updated);
        if (Number(updated.replace('%', '').replaceAll(',', '')) < 0 || Number(updated.replace('%', '').replaceAll(',', '')) > 100) {
            setOpen(true);
            setIsMargin(true);
        } else {
            let marginRegion = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostRegion, lMCostRegion, val,miscellaneousRegion));
            let marginUnitTonnage = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostUnitTonnage, lMCostUnitTonnage, val,miscellaneousUnitTonnage));
            let marginAcHp = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostAcHp, lMCostAcHp, val,miscellaneousAcHp));
            let marginFrnAh = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostfrnAh, lMCostfrnAh, val,miscellaneousfrnAh));
            let marginBrand = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostBrand, lMCostBrand, val,miscellaneousBrand));
            let marginMargin = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostMargin, lMCostMargin, val,miscellaneousMargin));
            let marginLast = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostLast, lMCostLast, val,miscellaneousLast));
            setmarginDealerRegion(marginRegion)
            setmarginDealerUnitTonnage(marginUnitTonnage)
            setmarginDealerAcHp(marginAcHp)
            setmarginDealerfrnAh(marginFrnAh)
            setmarginDealerBrand(marginBrand)
            setmarginDealerMargin(marginMargin)
            setmarginDealerLast(marginLast)

            let beforeRegion = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostRegion, lMCostRegion, marginRegion,miscellaneousRegion));
            let beforeUnit = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostUnitTonnage, lMCostUnitTonnage, marginUnitTonnage,miscellaneousUnitTonnage));
            let beforeAchp = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostAcHp, lMCostAcHp, marginAcHp,miscellaneousAcHp));
            let beforeFrn = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostfrnAh, lMCostfrnAh, marginFrnAh,miscellaneousfrnAh));
            let beforeBrand = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostBrand, lMCostBrand, marginBrand,miscellaneousBrand));
            let beforeMargin = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostMargin, lMCostMargin, marginMargin,miscellaneousMargin));
            let beforeLast = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostLast, lMCostLast, marginLast,miscellaneousLast));

            setBeforePriceRegion(beforeRegion)
            setBeforePriceUnitTonnage(beforeUnit)
            setBeforePriceAcHp(beforeAchp)
            setBeforePricefrnAh(beforeFrn)
            setBeforePriceBrand(beforeBrand)
            setBeforePriceMargin(beforeMargin)
            setBeforePriceLast(beforeLast)
            let afterRegion = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostRegion, lMCostRegion, marginRegion, localeRebateRegion, outDoorRegion, furnaceRegion,miscellaneousRegion));
            let afterUnit = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostUnitTonnage, lMCostUnitTonnage, marginUnitTonnage, localeRebateUnitTonnage, outDoorUnitTonnage, furnaceUnitTonnage,miscellaneousUnitTonnage));
            let afterAchp = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostAcHp, lMCostAcHp, marginAcHp, localeRebateAcHp, outDoorAcHp, furnaceAcHp,miscellaneousAcHp))
            let afterFrn = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostfrnAh, lMCostfrnAh, marginFrnAh, localeRebatefrnAh, outDoorfrnAh, furnacefrnAh,miscellaneousfrnAh))
            let afterBrand = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostBrand, lMCostBrand, marginBrand, localeRebateBrand, outDoorBrand, furnaceBrand,miscellaneousBrand))
            let afterMargin = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostMargin, lMCostMargin, marginMargin, localeRebateMargin, outDoorMargin, furnaceMargin,miscellaneousMargin))
            let afterLast = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostLast, lMCostLast, marginLast, localeRebateLast, outDoorLast, furnaceLast,miscellaneousLast))
            setAfterPriceRegion(afterRegion)
            setAfterPriceUnitTonnage(afterUnit)
            setAfterPriceAcHp(afterAchp)
            setAfterPricefrnAh(afterFrn)
            setAfterPriceBrand(afterBrand)
            setAfterPriceMargin(afterMargin)
            setAfterPriceLast(afterLast)
            UpdateBgColor(beforeRegion, beforeUnit, beforeAchp, beforeFrn, beforeBrand, beforeMargin, beforeLast, 'before')
            UpdateBgColor(afterRegion, afterUnit, afterAchp, afterFrn, afterBrand, afterMargin, afterLast, 'after')
            UpdateBgColor(marginRegion, marginUnitTonnage, marginAcHp, marginFrnAh, marginBrand, marginMargin, marginLast, 'dealer')
        }
        return val;
    }

    const handleProductRegion = (val) => {
        if (val == 'N/A') {
            setEquipCostRegion("$0");
            setLMCostRegion("$0");
            setmarginDealerRegion("$0");
            setLocaleRebateRegion("$0");
            setAfterPriceRegion("$0");
            setBeforePriceRegion("$0")
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'region', furnaceRegion, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, "$0", "$0", "$0", "$0","$0");
            UpdateBgColor("$0", beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor("$0", afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor("$0", marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        } else {
            setEquipCostRegion(equipCostRegion);
            setLMCostRegion(lMCostRegion);
            setmarginDealerRegion(marginDealerRegion);
            setLocaleRebateRegion(localeRebateRegion);
            setAfterPriceRegion(afterPriceRegion);
            setBeforePriceRegion(beforePriceRegion)
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'region', furnaceRegion, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, equipCostRegion, lMCostRegion, marginDealerRegion, localeRebateRegion,miscellaneousRegion);
        }
        setProductRegion(val);
        return val;
    }
    const handleProductUnitTonnage = (val) => {

        if (val == 'N/A') {
            setEquipCostUnitTonnage("$0");
            setLMCostUnitTonnage("$0");
            setmarginDealerUnitTonnage("$0");
            setLocaleRebateUnitTonnage("$0");
            setAfterPriceUnitTonnage("$0");
            setBeforePriceUnitTonnage("$0")
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'unitTonnage', furnaceUnitTonnage, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, "$0", "$0", "$0", "$0","$0");
            UpdateBgColor(beforePriceRegion, "$0", beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, "$0", afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, "$0", marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        } else {
            setEquipCostUnitTonnage(equipCostUnitTonnage);
            setLMCostUnitTonnage(lMCostUnitTonnage);
            setmarginDealerUnitTonnage(marginDealerUnitTonnage);
            setLocaleRebateUnitTonnage(localeRebateUnitTonnage);
            setAfterPriceUnitTonnage(afterPriceUnitTonnage);
            setBeforePriceUnitTonnage(beforePriceUnitTonnage)
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'unitTonnage', furnaceUnitTonnage, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, localeRebateUnitTonnage,miscellaneousUnitTonnage);
        }
        setProductUnitTonnage(val);
        return val;
    }
    const handleProductAcHp = (val, initialData = '') => {
        if (val == 'N/A') {
            setEquipCostAcHp("$0");
            setLMCostAcHp("$0");
            setmarginDealerAcHp("$0");
            setLocaleRebateAcHp("$0");
            setAfterPriceAcHp("$0");
            setBeforePriceAcHp("$0")
            filterProductFamily(secondTData?.Od_Model == undefined ? initialData : secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'ac_hp', furnaceAcHp, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, "$0", "$0", "$0", "$0","$0");
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, "$0", beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, "$0", afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, "$0", marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        } else {
            setEquipCostAcHp(equipCostAcHp);
            setLMCostAcHp(lMCostAcHp);
            setmarginDealerAcHp(marginDealerAcHp);
            setLocaleRebateAcHp(localeRebateAcHp);
            setAfterPriceAcHp(afterPriceAcHp);
            setBeforePriceAcHp(beforePriceAcHp)
            filterProductFamily(secondTData?.Od_Model == undefined ? initialData : secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'ac_hp', furnaceAcHp, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, equipCostAcHp, lMCostAcHp, marginDealerAcHp, localeRebateAcHp,miscellaneousAcHp);
        }
        setProductAcHp(val);
        return val;
    }
    const handleProductFrnAh = (val) => {

        if (val == 'N/A') {
            setEquipCostfrnAh("$0");
            setLMCostfrnAh("$0");
            setmarginDealerfrnAh("$0");
            setLocaleRebatefrnAh("$0");
            setAfterPricefrnAh("$0");
            setBeforePricefrnAh("$0")
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'frn_ah', furnacefrnAh, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, "$0", "$0", "$0", "$0","$0");
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, "$0", beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, "$0", afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, "$0", marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        } else {
            setEquipCostfrnAh(equipCostfrnAh);
            setLMCostfrnAh(lMCostfrnAh);
            setmarginDealerfrnAh(marginDealerfrnAh);
            setLocaleRebatefrnAh(localeRebatefrnAh);
            setAfterPricefrnAh(afterPricefrnAh);
            setBeforePricefrnAh(beforePricefrnAh)
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'frn_ah', furnacefrnAh, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, localeRebatefrnAh,miscellaneousfrnAh);
        }
        setProductfrnAh(val);
        return val;
    }
    const handleProductBrand = (val) => {
        if (val == 'N/A') {
            setEquipCostBrand("$0");
            setLMCostBrand("$0");
            setmarginDealerBrand("$0");
            setLocaleRebateBrand("$0");
            setAfterPriceBrand("$0");
            setBeforePriceBrand("$0")
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'brand', furnaceBrand, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, "$0", "$0", "$0", "$0","$0");
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, "$0", beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, "$0", afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, "$0", marginDealerMargin, marginDealerLast, 'dealer')
        } else {
            setEquipCostBrand(equipCostBrand);
            setLMCostBrand(lMCostBrand);
            setmarginDealerBrand(marginDealerBrand);
            setLocaleRebateBrand(localeRebateBrand);
            setAfterPriceBrand(afterPriceBrand);
            setBeforePriceBrand(beforePriceBrand)
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'brand', furnaceBrand, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, equipCostBrand, lMCostBrand, marginDealerBrand, localeRebateBrand,miscellaneousBrand);
        }
        setProductBrand(val);
        return val;
    }
    const handleProductMargin = (val) => {
        if (val == 'N/A') {
            setEquipCostMargin("$0");
            setLMCostMargin("$0");
            setmarginDealerMargin("$0");
            setLocaleRebateMargin("$0");
            setAfterPriceMargin("$0");
            setBeforePriceMargin("$0")
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'margin', furnaceMargin, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, "$0", "$0", "$0", "$0","$0");
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, "$0", beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, "$0", afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, "$0", marginDealerLast, 'dealer')
        } else {
            setEquipCostMargin(equipCostMargin);
            setLMCostMargin(lMCostMargin);
            setmarginDealerMargin(marginDealerMargin);
            setLocaleRebateMargin(localeRebateMargin);
            setAfterPriceMargin(afterPriceMargin);
            setBeforePriceMargin(beforePriceMargin)
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'margin', furnaceMargin, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, equipCostMargin, lMCostMargin, marginDealerMargin, localeRebateMargin,miscellaneousMargin);
        }
        setProductMargin(val);
        return val;
    }
    const handleProductLast = (val) => {
        if (val == 'N/A') {
            setEquipCostLast("$0");
            setLMCostLast("$0");
            setmarginDealerLast("$0");
            setLocaleRebateLast("$0");
            setAfterPriceLast("$0");
            setBeforePriceLast("$0")
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'last', furnaceLast, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, "$0", "$0", "$0", "$0","$0");
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, "$0", 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, "$0", 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, "$0", 'dealer')
        } else {
            setEquipCostLast(equipCostLast);
            setLMCostLast(lMCostLast);
            setmarginDealerLast(marginDealerLast);
            setLocaleRebateLast(localeRebateLast);
            setAfterPriceLast(afterPriceLast);
            setBeforePriceLast(beforePriceLast)
            filterProductFamily(secondTData?.Od_Model, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, 'last', furnaceLast, furnaceRegion, furnaceUnitTonnage, furnaceAcHp, furnacefrnAh, furnaceBrand, furnaceMargin, furnaceLast, equipCostLast, lMCostLast, marginDealerLast, localeRebateLast,miscellaneousLast);
        }
        setProductLast(val);
        return val;
    }

    const handleODModelRegion = (val) => {
        thirdODModelApiCall(val, '', '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "ODModel", 'region', furnaceRegion, equipCostRegion, lMCostRegion, marginDealerRegion, localeRebateRegion,miscellaneousRegion);
        setODModelRegion(val);
        return val;
    }
    const handleODModelUnitTonnage = (val) => {
        thirdODModelApiCall(val, '', '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "ODModel", 'unitTonnage', furnaceUnitTonnage, equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, localeRebateUnitTonnage,miscellaneousUnitTonnage);
        setODModelUnitTonnage(val);
        return val;
    }
    const handleODModelAcHp = (val) => {
        thirdODModelApiCall(val, '', '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "ODModel", 'ac_hp', furnaceAcHp, equipCostAcHp, lMCostAcHp, marginDealerAcHp, localeRebateAcHp,miscellaneousAcHp);
        setODModelAcHp(val);
        return val;
    }
    const handleODModelFrnAh = (val) => {
        thirdODModelApiCall(val, '', '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "ODModel", 'frn_ah', furnacefrnAh, equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, localeRebatefrnAh,miscellaneousAcHp);
        setODModelfrnAh(val);
        return val;
    }
    const handleODModelBrand = (val) => {
        thirdODModelApiCall(val, '', '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "ODModel", 'brand', furnaceBrand, equipCostBrand, lMCostBrand, marginDealerBrand, localeRebateBrand,miscellaneousBrand);
        setODModelBrand(val);
        return val;
    }
    const handleODModelMargin = (val) => {
        thirdODModelApiCall(val, '', '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "ODModel", 'margin', furnaceMargin, equipCostMargin, lMCostMargin, marginDealerMargin, localeRebateMargin,miscellaneousMargin);
        setODModelMargin(val);
        return val;
    }
    const handleODModelLast = (val) => {
        thirdODModelApiCall(val, '', '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "ODModel", 'last', furnaceLast, equipCostLast, lMCostLast, marginDealerLast, localeRebateLast,miscellaneousLast);
        setODModelLast(val);
        return val;
    }
    const handleEquipRegion = (val) => {
        let valid = updateFurnace(lookUpValidate, val, 'region',miscellaneousRegion);
        thirdODModelApiCall(oDModelRegion, val, '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "Qualifying_Coil", 'region', valid, equipCostRegion, lMCostRegion, marginDealerRegion, localeRebateRegion,miscellaneousRegion)
        setEquipRegion(val);
        return val;
    }
    const handleEquipUnitTonnage = (val) => {
        let valid = updateFurnace(lookUpValidate, val, 'unitTonnage',miscellaneousUnitTonnage);
        thirdODModelApiCall(oDModelUnitTonnage, val, '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "Qualifying_Coil", 'unitTonnage', valid, equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, localeRebateUnitTonnage,miscellaneousUnitTonnage)
        setEquipUnitTonnage(val);
        return val;
    }
    const handleEquipAcHp = (val) => {
        let valid = updateFurnace(lookUpValidate, val, 'ac_hp',miscellaneousAcHp);
        thirdODModelApiCall(oDModelAcHp, val, '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "Qualifying_Coil", 'ac_hp', valid, equipCostAcHp, lMCostAcHp, marginDealerAcHp, localeRebateAcHp,miscellaneousAcHp)
        setEquipAcHp(val);
        return val;
    }
    const handleEquipFrnAh = (val) => {
        let valid = updateFurnace(lookUpValidate, val, 'frn_ah',miscellaneousfrnAh);
        thirdODModelApiCall(oDModelfrnAh, val, '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "Qualifying_Coil", 'frn_ah', valid, equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, localeRebatefrnAh,miscellaneousfrnAh)
        setEquipfrnAh(val);
        return val;
    }
    const handleEquipBrand = (val) => {
        let valid = updateFurnace(lookUpValidate, val, 'brand',miscellaneousBrand);
        thirdODModelApiCall(oDModelBrand, val, '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "Qualifying_Coil", 'brand', valid, equipCostBrand, lMCostBrand, marginDealerBrand, localeRebateBrand,miscellaneousBrand)
        setEquipBrand(val);
        return val;
    }
    const handleEquipMargin = (val) => {
        let valid = updateFurnace(lookUpValidate, val, 'margin',miscellaneousMargin);
        thirdODModelApiCall(oDModelMargin, val, '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "Qualifying_Coil", 'margin', valid, equipCostMargin, lMCostMargin, marginDealerMargin, localeRebateMargin,miscellaneousMargin)
        setEquipMargin(val);
        return val;
    }
    const handleEquipLast = (val) => {
        let valid = updateFurnace(lookUpValidate, val, 'last',miscellaneousLast);
        thirdODModelApiCall(oDModelLast, val, '', mainRegion, mainAcHp, mainfrnAh, mainBrand, "Qualifying_Coil", 'last', valid, equipCostLast, lMCostLast, marginDealerLast, localeRebateLast,miscellaneousLast)
        setEquipLast(val);
        return val;
    }
    const handleCoilRegion = (val) => {
        thirdODModelApiCall(oDModelRegion, equipRegion, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, "Ahri", 'region', furnaceRegion, equipCostRegion, lMCostRegion, marginDealerRegion, localeRebateRegion,miscellaneousRegion)
        setCoilRegion(val);
        return val;
    }
    const handleCoilUnitTonnage = (val) => {
        thirdODModelApiCall(oDModelUnitTonnage, equipUnitTonnage, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, "Ahri", 'unitTonnage', furnaceUnitTonnage, equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, localeRebateUnitTonnage,miscellaneousUnitTonnage)
        setCoilUnitTonnage(val);
        return val;
    }
    const handleCoilAcHp = (val) => {
        thirdODModelApiCall(oDModelAcHp, equipAcHp, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, "Ahri", 'ac_hp', furnaceAcHp, equipCostAcHp, lMCostAcHp, marginDealerAcHp, localeRebateAcHp,miscellaneousAcHp)
        setCoilAcHp(val);
        return val;
    }
    const handleCoilFrnAh = (val) => {
        thirdODModelApiCall(oDModelfrnAh, equipfrnAh, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, "Ahri", 'frn_ah', furnacefrnAh, equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, localeRebatefrnAh,miscellaneousfrnAh)
        setCoilfrnAh(val);
        return val;
    }
    const handleCoilBrand = (val) => {
        thirdODModelApiCall(oDModelBrand, equipBrand, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, "Ahri", 'brand', furnaceBrand, equipCostBrand, lMCostBrand, marginDealerBrand, localeRebateBrand,miscellaneousBrand)
        setCoilBrand(val);
        return val;
    }
    const handleCoilMargin = (val) => {
        thirdODModelApiCall(oDModelMargin, equipMargin, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, "Ahri", 'margin', furnaceMargin, equipCostMargin, lMCostMargin, marginDealerMargin, localeRebateMargin,miscellaneousMargin)
        setCoilMargin(val);
        return val;
    }
    const handleCoilLast = (val) => {
        thirdODModelApiCall(oDModelLast, equipLast, val, mainRegion, mainAcHp, mainfrnAh, mainBrand, "Ahri", 'last', furnaceLast, equipCostLast, lMCostLast, marginDealerLast, localeRebateLast,miscellaneousLast)
        setCoilLast(val);
        return val;
    }

    const handleEquipCostRegion = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setEquipCostRegion(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(updated, lMCostRegion, mainMargin,miscellaneousRegion));
            setmarginDealerRegion(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(updated, lMCostRegion, marginVal,miscellaneousRegion));
            setBeforePriceRegion(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(updated, lMCostRegion, marginVal, localeRebateRegion, outDoorRegion, furnaceRegion,miscellaneousRegion));
            setAfterPriceRegion(afterVal)
            updateOutDoor(aHRIRegion, mainAcHp, 'region', furnaceRegion, updated, lMCostRegion, marginVal, localeRebateRegion,miscellaneousRegion);
            UpdateBgColor(beforeVal, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterVal, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginVal, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')

        }
        return (val);
    }
    const handleEquipCostUnitTonnage = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setEquipCostUnitTonnage(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(updated, lMCostUnitTonnage, mainMargin,miscellaneousUnitTonnage));
            setmarginDealerUnitTonnage(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(updated, lMCostUnitTonnage, marginVal,miscellaneousUnitTonnage));
            setBeforePriceUnitTonnage(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(updated, lMCostUnitTonnage, marginVal, localeRebateUnitTonnage, outDoorUnitTonnage, furnaceUnitTonnage,miscellaneousUnitTonnage));
            setAfterPriceUnitTonnage(afterVal)
            updateOutDoor(aHRIUnitTonnage, mainAcHp, 'unitTonnage', furnaceUnitTonnage, updated, lMCostUnitTonnage, marginVal, localeRebateUnitTonnage,miscellaneousUnitTonnage);
            UpdateBgColor(beforePriceRegion, beforeVal, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterVal, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginVal, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleEquipCostAcHp = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setEquipCostAcHp(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(updated, lMCostAcHp, mainMargin,miscellaneousAcHp));
            setmarginDealerAcHp(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(updated, lMCostAcHp, marginVal,miscellaneousAcHp));
            setBeforePriceAcHp(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(updated, lMCostAcHp, marginVal, localeRebateAcHp, outDoorAcHp, furnaceAcHp,miscellaneousAcHp));
            setAfterPriceAcHp(afterVal)
            updateOutDoor(aHRIAcHp, mainAcHp, 'ac_hp', furnaceAcHp, updated, lMCostAcHp, marginVal, localeRebateAcHp,miscellaneousAcHp);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforeVal, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterVal, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginVal, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleEquipCostfrnAh = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setEquipCostfrnAh(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(updated, lMCostfrnAh, mainMargin,miscellaneousfrnAh));
            setmarginDealerfrnAh(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(updated, lMCostfrnAh, marginVal,miscellaneousfrnAh));
            setBeforePricefrnAh(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(updated, lMCostfrnAh, marginVal, localeRebatefrnAh, outDoorfrnAh, furnacefrnAh,miscellaneousfrnAh));
            setAfterPricefrnAh(afterVal)
            updateOutDoor(aHRIfrnAh, mainAcHp, 'frn_ah', furnacefrnAh, updated, lMCostfrnAh, marginVal, localeRebatefrnAh,miscellaneousfrnAh);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforeVal, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterVal, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginVal, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleEquipCostBrand = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setEquipCostBrand(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(updated, lMCostBrand, mainMargin,miscellaneousBrand));
            setmarginDealerBrand(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(updated, lMCostBrand, marginVal,miscellaneousBrand));
            setBeforePriceBrand(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(updated, lMCostBrand, marginVal, localeRebateBrand, outDoorBrand, furnaceBrand,miscellaneousBrand));
            setAfterPriceBrand(afterVal)
            updateOutDoor(aHRIBrand, mainAcHp, 'brand', furnaceBrand, updated, lMCostBrand, marginVal, localeRebateBrand,miscellaneousBrand);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforeVal, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterVal, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginVal, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleEquipCostMargin = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setEquipCostMargin(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(updated, lMCostMargin, mainMargin,miscellaneousMargin));
            setmarginDealerMargin(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(updated, lMCostMargin, marginVal,miscellaneousMargin));
            setBeforePriceMargin(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(updated, lMCostMargin, marginVal, localeRebateMargin, outDoorMargin, furnaceMargin,miscellaneousMargin));
            setAfterPriceMargin(afterVal)
            updateOutDoor(aHRIMargin, mainAcHp, 'margin', furnaceMargin, updated, lMCostMargin, marginVal, localeRebateMargin,miscellaneousMargin);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforeVal, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterVal, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginVal, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleEquipCostLast = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setEquipCostLast(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(updated, lMCostLast, mainMargin,miscellaneousLast));
            setmarginDealerLast(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(updated, lMCostLast, marginVal,miscellaneousLast));
            setBeforePriceLast(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(updated, lMCostLast, marginVal, localeRebateLast, outDoorLast, furnaceLast,miscellaneousLast));
            setAfterPriceLast(afterVal)
            updateOutDoor(aHRILast, mainAcHp, 'last', furnaceLast, updated, lMCostLast, marginVal, localeRebateLast,miscellaneousLast);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforeVal, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterVal, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginVal, 'dealer')
        }
        return (val);
    }


    // miscellaneous start -----------------------------------
    const handleMiscellaneousRegion = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setMiscellaneousRegion(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostRegion, lMCostRegion, mainMargin, updated));
            setmarginDealerRegion(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostRegion, lMCostRegion, marginVal, updated));
            setBeforePriceRegion(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostRegion, lMCostRegion, marginVal, localeRebateRegion, outDoorRegion, furnaceRegion, updated));
            setAfterPriceRegion(afterVal)
            updateOutDoor(aHRIRegion, mainAcHp, 'region', furnaceRegion, equipCostRegion, lMCostRegion, marginVal, localeRebateRegion,updated);
            UpdateBgColor(beforeVal, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterVal, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginVal, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')

        }
        return (val);
    }
    const handleMiscellaneousUnitTonnage = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setMiscellaneousUnitTonnage(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostUnitTonnage, lMCostUnitTonnage, mainMargin, updated));
            setmarginDealerUnitTonnage(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostUnitTonnage, lMCostUnitTonnage, marginVal, updated));
            setBeforePriceUnitTonnage(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostUnitTonnage, lMCostUnitTonnage, marginVal, localeRebateUnitTonnage, outDoorUnitTonnage, furnaceUnitTonnage, updated));
            setAfterPriceUnitTonnage(afterVal)
            updateOutDoor(aHRIUnitTonnage, mainAcHp, 'unitTonnage', furnaceUnitTonnage, equipCostUnitTonnage, lMCostUnitTonnage, marginVal, localeRebateUnitTonnage,updated);
            UpdateBgColor(beforePriceRegion, beforeVal, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterVal, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginVal, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleMiscellaneousAcHp = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setMiscellaneousAcHp(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostAcHp, lMCostAcHp, mainMargin,updated));
            setmarginDealerAcHp(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostAcHp, lMCostAcHp, marginVal, updated));
            setBeforePriceAcHp(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostAcHp, lMCostAcHp, marginVal, localeRebateAcHp, outDoorAcHp, furnaceAcHp, updated));
            setAfterPriceAcHp(afterVal)
            updateOutDoor(aHRIAcHp, mainAcHp, 'ac_hp', furnaceAcHp, equipCostAcHp, lMCostAcHp, marginVal, localeRebateAcHp,updated);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforeVal, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterVal, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginVal, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleMiscellaneousfrnAh = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setMiscellaneousfrnAh(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostfrnAh, lMCostfrnAh, mainMargin,updated));
            setmarginDealerfrnAh(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostfrnAh, lMCostfrnAh, marginVal, updated));
            setBeforePricefrnAh(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostfrnAh, lMCostfrnAh, marginVal, localeRebatefrnAh, outDoorfrnAh, furnacefrnAh, updated));
            setAfterPricefrnAh(afterVal)
            updateOutDoor(aHRIfrnAh, mainAcHp, 'frn_ah', furnacefrnAh, equipCostfrnAh, lMCostfrnAh, marginVal, localeRebatefrnAh,updated);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforeVal, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterVal, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginVal, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleMiscellaneousBrand = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setMiscellaneousBrand(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostBrand, lMCostBrand, mainMargin,updated));
            setmarginDealerBrand(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostBrand, lMCostBrand, marginVal, updated));
            setBeforePriceBrand(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostBrand, lMCostBrand, marginVal, localeRebateBrand, outDoorBrand, furnaceBrand, updated));
            setAfterPriceBrand(afterVal)
            updateOutDoor(aHRIBrand, mainAcHp, 'brand', furnaceBrand, equipCostBrand, lMCostBrand, marginVal, localeRebateBrand,updated);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforeVal, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterVal, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginVal, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleMiscellaneousMargin = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setMiscellaneousMargin(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostMargin, lMCostMargin, mainMargin, updated));
            setmarginDealerMargin(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostMargin, lMCostMargin, marginVal, updated));
            setBeforePriceMargin(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostMargin, lMCostMargin, marginVal, localeRebateMargin, outDoorMargin, furnaceMargin, updated));
            setAfterPriceMargin(afterVal)
            updateOutDoor(aHRIMargin, mainAcHp, 'margin', furnaceMargin, equipCostMargin, lMCostMargin, marginVal, localeRebateMargin,updated);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforeVal, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterVal, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginVal, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleMiscellaneousLast = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setMiscellaneousLast(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostLast, lMCostLast, mainMargin, updated));
            setmarginDealerLast(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostLast, lMCostLast, marginVal, updated));
            setBeforePriceLast(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostLast, lMCostLast, marginVal, localeRebateLast, outDoorLast, furnaceLast, updated));
            setAfterPriceLast(afterVal)
            updateOutDoor(aHRILast, mainAcHp, 'last', furnaceLast, equipCostLast, lMCostLast, marginVal, localeRebateLast,updated);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforeVal, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterVal, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginVal, 'dealer')
        }
        return (val);
    }

    // miscellaneous end -----------------------------------
    const handleLMCostRegion = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLMCostRegion(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostRegion, updated, mainMargin,miscellaneousRegion));
            setmarginDealerRegion(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostRegion, updated, marginVal,miscellaneousRegion));
            setBeforePriceRegion(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostRegion, updated, marginVal, localeRebateRegion, outDoorRegion, furnaceRegion,miscellaneousRegion));
            setAfterPriceRegion(afterVal)
            updateOutDoor(aHRIRegion, mainAcHp, 'region', furnaceRegion, equipCostRegion, updated, marginVal, localeRebateRegion,miscellaneousRegion);
            UpdateBgColor(beforeVal, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterVal, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginVal, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleLMCostUnitTonnage = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLMCostUnitTonnage(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostUnitTonnage, updated, mainMargin, miscellaneousUnitTonnage));
            setmarginDealerUnitTonnage(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostUnitTonnage, updated, marginVal, miscellaneousUnitTonnage));
            setBeforePriceUnitTonnage(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostUnitTonnage, updated, marginVal, localeRebateUnitTonnage, outDoorUnitTonnage, furnaceUnitTonnage, miscellaneousUnitTonnage));
            setAfterPriceUnitTonnage(afterVal)
            updateOutDoor(aHRIUnitTonnage, mainAcHp, 'unitTonnage', furnaceUnitTonnage, equipCostUnitTonnage, updated, marginVal, localeRebateUnitTonnage,miscellaneousUnitTonnage);
            UpdateBgColor(beforePriceRegion, beforeVal, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterVal, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginVal, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleLMCostAcHp = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLMCostAcHp(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostAcHp, updated, mainMargin,miscellaneousAcHp));
            setmarginDealerAcHp(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostAcHp, updated, marginVal,miscellaneousAcHp));
            setBeforePriceAcHp(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostAcHp, updated, marginVal, localeRebateAcHp, outDoorAcHp, furnaceAcHp,miscellaneousAcHp));
            setAfterPriceAcHp(afterVal)
            updateOutDoor(aHRIAcHp, mainAcHp, 'ac_hp', furnaceAcHp, equipCostAcHp, updated, marginVal, localeRebateAcHp,miscellaneousAcHp);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforeVal, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterVal, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginVal, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleLMCostfrnAh = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLMCostfrnAh(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostfrnAh, updated, mainMargin,miscellaneousfrnAh));
            setmarginDealerfrnAh(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostfrnAh, updated, marginVal,miscellaneousfrnAh));
            setBeforePricefrnAh(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostfrnAh, updated, marginVal, localeRebatefrnAh, outDoorfrnAh, furnacefrnAh,miscellaneousfrnAh));
            setAfterPricefrnAh(afterVal)
            updateOutDoor(aHRIfrnAh, mainAcHp, 'frn_ah', furnacefrnAh, equipCostfrnAh, updated, marginVal, localeRebatefrnAh,miscellaneousfrnAh);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforeVal, beforePriceBrand, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterVal, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginVal, marginDealerBrand, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleLMCostBrand = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLMCostBrand(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostBrand, updated, mainMargin,miscellaneousBrand));
            setmarginDealerBrand(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostBrand, updated, marginVal,miscellaneousBrand));
            setBeforePriceBrand(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostBrand, updated, marginVal, localeRebateBrand, outDoorBrand, furnaceBrand,miscellaneousBrand));
            setAfterPriceBrand(afterVal)
            updateOutDoor(aHRIBrand, mainAcHp, 'brand', furnaceBrand, equipCostBrand, updated, marginVal, localeRebateBrand,miscellaneousBrand);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforeVal, beforePriceMargin, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterVal, afterPriceMargin, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginVal, marginDealerMargin, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleLMCostMargin = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLMCostMargin(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostMargin, updated, mainMargin,miscellaneousMargin));
            setmarginDealerMargin(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostMargin, updated, marginVal,miscellaneousMargin));
            setBeforePriceMargin(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostMargin, updated, marginVal, localeRebateMargin, outDoorMargin, furnaceMargin,miscellaneousMargin));
            setAfterPriceMargin(afterVal)
            updateOutDoor(aHRIMargin, mainAcHp, 'margin', furnaceMargin, equipCostMargin, updated, marginVal, localeRebateMargin,miscellaneousMargin);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforeVal, beforePriceLast, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterVal, afterPriceLast, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginVal, marginDealerLast, 'dealer')
        }
        return (val);
    }
    const handleLMCostLast = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLMCostLast(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let marginVal = formatCurrencyNumberWithDecimal(updateMarginDealer(equipCostLast, updated, mainMargin,miscellaneousLast));
            setmarginDealerLast(marginVal)
            let beforeVal = formatCurrencyNumberWithDecimal(updateBeforePrice(equipCostLast, updated, marginVal,miscellaneousLast));
            setBeforePriceLast(beforeVal)
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostLast, updated, marginVal, localeRebateLast, outDoorLast, furnaceLast,miscellaneousLast));
            setAfterPriceLast(afterVal)
            updateOutDoor(aHRILast, mainAcHp, 'last', furnaceLast, equipCostLast, updated, marginVal, localeRebateLast,miscellaneousLast);
            UpdateBgColor(beforePriceRegion, beforePriceUnitTonnage, beforePriceAcHp, beforePricefrnAh, beforePriceBrand, beforePriceMargin, beforeVal, 'before')
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterVal, 'after')
            UpdateBgColor(marginDealerRegion, marginDealerUnitTonnage, marginDealerAcHp, marginDealerfrnAh, marginDealerBrand, marginDealerMargin, marginVal, 'dealer')
        }
        return (val);
    }

    const handleLocaleRebateRegion = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLocaleRebateRegion(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostRegion, lMCostRegion, marginDealerRegion, updated, outDoorRegion, furnaceRegion,miscellaneousRegion));
            UpdateBgColor(afterVal, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            setAfterPriceRegion(afterVal)
        }
        return (val);
    }
    const handleLocaleRebateUnitTonnage = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLocaleRebateUnitTonnage(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, updated, outDoorUnitTonnage, furnaceUnitTonnage,miscellaneousUnitTonnage));
            setAfterPriceUnitTonnage(afterVal)
            UpdateBgColor(afterPriceRegion, afterVal, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
        }
        return (val);
    }
    const handleLocaleRebateAcHp = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLocaleRebateAcHp(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostAcHp, lMCostAcHp, marginDealerAcHp, updated, outDoorAcHp, furnaceAcHp,miscellaneousAcHp));
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterVal, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            setAfterPriceAcHp(afterVal)
        }
        return (val);
    }
    const handleLocaleRebatefrnAh = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLocaleRebatefrnAh(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, updated, outDoorfrnAh, furnacefrnAh,miscellaneousfrnAh));
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterVal, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
            setAfterPricefrnAh(afterVal)
        }
        return (val);
    }
    const handleLocaleRebateBrand = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLocaleRebateBrand(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostBrand, lMCostBrand, marginDealerBrand, updated, outDoorBrand, furnaceBrand,miscellaneousBrand));
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterVal, afterPriceMargin, afterPriceLast, 'after')
            setAfterPriceBrand(afterVal)
        }
        return (val);
    }
    const handleLocaleRebateMargin = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLocaleRebateMargin(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostMargin, lMCostMargin, marginDealerMargin, updated, outDoorMargin, furnaceMargin,miscellaneousMargin));
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterVal, afterPriceLast, 'after')
            setAfterPriceMargin(afterVal)
        }
        return (val);
    }
    const handleLocaleRebateLast = (val) => {
        let updated = formatCurrencyNumberWithDecimal(val)
        setLocaleRebateLast(updated);
        if (Number(updated.replace('$', '').replaceAll(',', '')) < 0 || Number(updated.replace('$', '').replaceAll(',', '')) > 15000) {
            setOpen(true);
        } else {
            let afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostLast, lMCostLast, marginDealerLast, updated, outDoorLast, furnaceLast,miscellaneousLast));
            UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterVal, 'after')
            setAfterPriceLast(afterVal)
        }
        return (val);
    }
    const updateOutDoor = (ahri, achp = mainAcHp, col, fur, equip = '', lmCost = '', mDealer = '', local = '', misc='') => {
        let afterVal;
        // console.log('outdoor: ', ahri,' achp: ', achp,'reg: ', ((ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000'), 'col: ', col, " total:", equip, lmCost, mDealer, local, fur,misc)
        switch (col) {
            case 'region': let region = (ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000';
                setOutDoorRegion(region)
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equip, lmCost, mDealer, local, region, fur,misc));
                setAfterPriceRegion(afterVal)
                UpdateBgColor(afterVal, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                break;
            case 'unitTonnage': let unitTonnage = (ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000';
                setOutDoorUnitTonnage(unitTonnage)
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equip, lmCost, mDealer, local, unitTonnage, fur,misc));
                setAfterPriceUnitTonnage(afterVal)
                UpdateBgColor(afterPriceRegion, afterVal, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                break;
            case 'ac_hp': let ach = (ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000';
                setOutDoorAcHp(ach)
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equip, lmCost, mDealer, local, ach, fur,misc));
                setAfterPriceAcHp(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterVal, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                break;
            case 'frn_ah': let frn = (ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000';
                setOutDoorfrnAh(frn)
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equip, lmCost, mDealer, local, frn, fur,misc))
                setAfterPricefrnAh(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterVal, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                break;
            case 'brand': let brand = (ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000';
                setOutDoorBrand(brand)
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equip, lmCost, mDealer, local, brand, fur,misc))
                setAfterPriceBrand(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterVal, afterPriceMargin, afterPriceLast, 'after')
                break;
            case 'margin': let margin = (ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000';
                setOutDoorMargin(margin)
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equip, lmCost, mDealer, local, margin, fur,misc))
                setAfterPriceMargin(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterVal, afterPriceLast, 'after')
                break;
            case 'last': let last = (ahri == 0 || ahri == null || ahri == 'N/A') ? "$0" : (achp == 'AC') ? '$600' : '$2000';
                setOutDoorLast(last)
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equip, lmCost, mDealer, local, last, fur,misc))
                setAfterPriceLast(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterVal, 'after')
                break;
        }
    }
    const updateFurnace = (lookUps = lookUpValidate, match, col, misc) => {
        let res;
        let afterVal;
        // console.log('currentfurnance  match', match, col, lookUps, misc)
        // console.log('furnance: ', 'reg: ', region, 'col: ', col, " total:", equip, lmCost, mDealer, local, region, fur,misc)
        switch (col) {
            case 'region': let region = ((lookUps['11DigitModel'].filter(x => match?.includes(x)).length > 0)) ? "$600" : '$0';
                setFurnaceRegion(region)
                res = region;
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostRegion, lMCostRegion, marginDealerRegion, localeRebateRegion, outDoorRegion, region,misc))
                setAfterPriceRegion(afterVal)
                UpdateBgColor(afterVal, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                return res;
                break;
            case 'unitTonnage': let unitTonnage = ((lookUps['11DigitModel'].filter(x => match?.includes(x)).length > 0)) ? "$600" : '$0';
                setFurnaceUnitTonnage(unitTonnage)
                res = unitTonnage;
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostUnitTonnage, lMCostUnitTonnage, marginDealerUnitTonnage, localeRebateUnitTonnage, outDoorUnitTonnage, unitTonnage, misc))
                setAfterPriceUnitTonnage(afterVal)
                UpdateBgColor(afterPriceRegion, afterVal, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                return res;
                break;
            case 'ac_hp': let acHp = ((lookUps['11DigitModel'].filter(x => match?.includes(x)).length > 0)) ? "$600" : '$0';
                setFurnaceAcHp(acHp);
                res = acHp;
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostAcHp, lMCostAcHp, marginDealerAcHp, localeRebateAcHp, outDoorAcHp, acHp,misc))
                setAfterPriceAcHp(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterVal, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                return res;
                break;
            case 'frn_ah': let frn = ((lookUps['11DigitModel'].filter(x => match?.includes(x)).length > 0)) ? "$600" : '$0';
                setFurnacefrnAh(frn)
                res = frn;
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostfrnAh, lMCostfrnAh, marginDealerfrnAh, localeRebatefrnAh, outDoorfrnAh, frn,misc))
                setAfterPricefrnAh(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterVal, afterPriceBrand, afterPriceMargin, afterPriceLast, 'after')
                return res;
                break;
            case 'brand': let brand = ((lookUps['11DigitModel'].filter(x => match?.includes(x)).length > 0)) ? "$600" : '$0';
                setFurnaceBrand(brand)
                res = brand;
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostBrand, lMCostBrand, marginDealerBrand, localeRebateBrand, outDoorBrand, brand,misc))
                setAfterPriceBrand(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterVal, afterPriceMargin, afterPriceLast, 'after')
                return res;
                break;
            case 'margin': let mar = ((lookUps['11DigitModel'].filter(x => match?.includes(x)).length > 0)) ? "$600" : '$0';
                setFurnaceMargin(mar)
                res = mar;
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostMargin, lMCostMargin, marginDealerMargin, localeRebateMargin, outDoorMargin, mar,misc))
                setAfterPriceMargin(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterVal, afterPriceLast, 'after')
                return res;
                break;
            case 'last': let last = ((lookUps['11DigitModel'].filter(x => match?.includes(x)).length > 0)) ? "$600" : '$0';
                setFurnaceLast(last)
                res = last;
                afterVal = formatCurrencyNumberWithDecimal(updateAfterPrice(equipCostLast, lMCostLast, marginDealerLast, localeRebateLast, outDoorLast, last,misc))
                setAfterPriceLast(afterVal)
                UpdateBgColor(afterPriceRegion, afterPriceUnitTonnage, afterPriceAcHp, afterPricefrnAh, afterPriceBrand, afterPriceMargin, afterVal, 'after')
                return res;
                break;
        }
        return res;
    }
    const updateMarginDealer = (eCost = "0", lMCost = "0", margin = mainMargin,misc='0') => {
        eCost = (eCost == null || eCost == "") ? '0' : eCost;
        lMCost = (lMCost == null || lMCost == "") ? '0' : lMCost;
        misc = (misc == null || misc == "") ? '0' : misc;
        let res = (Number(eCost?.replace('$', '').replaceAll(',', '')) + Number(lMCost?.replace('$', '').replaceAll(',', '')) + Number(misc?.replace('$', '').replaceAll(',', ''))) * (Number(margin?.replace('%', '')) / 100);
        return res ? String(Math.round(res)) : '0';
    }
    const updateBeforePrice = (eCost = "0", lMCost = "0", margin = "0",misc='0') => {
        eCost = (eCost == null || eCost == "") ? '0' : eCost;
        lMCost = (lMCost == null || lMCost == "") ? '0' : lMCost;
        misc = (misc == null || misc == "") ? '0' : misc;
        let res = (Number(eCost?.replace('$', '').replaceAll(',', '')) + Number(lMCost?.replace('$', '').replaceAll(',', '')) + Number(misc?.replace('$', '').replaceAll(',', ''))) + (Number(margin?.replace('$', '').replaceAll(',', '')));
        return res ? String(Math.round(res)) : '0';
    }
    const updateAfterPrice = (eCost = "$0", lMCost = "$0", margin = "$0", local = '$0', outDoor = '$0', furnace = '$0',misc='0') => {
        eCost = (eCost == null || eCost == "") ? '$0' : eCost;
        lMCost = (lMCost == null || lMCost == "") ? '$0' : lMCost;
        margin = (margin == null || margin == "") ? '$0' : margin;
        local = (local == null || local == "") ? '$0' : local;
        outDoor = (outDoor == null || outDoor == "") ? '$0' : outDoor;
        furnace = (furnace == null || furnace == "") ? '$0' : furnace;
        misc = (misc == null || misc == "") ? '0' : misc;
        let res = (Number(eCost?.replace('$', '').replaceAll(',', '')) + Number(lMCost?.replace('$', '').replaceAll(',', '')) + Number(misc?.replace('$', '').replaceAll(',', ''))) + (Number(margin?.replace('$', '').replaceAll(',', ''))) - (Number(local?.replace('$', '').replaceAll(',', ''))) - (Number(outDoor?.replace('$', '').replaceAll(',', ''))) - (Number(furnace?.replace('$', '').replaceAll(',', '')));
        // console.log('updateAfterprice: ', " ec: ",eCost,' lm: ', lMCost,' mr: ', margin,' loc: ', local,' out: ',outDoor, ' fur: ',furnace,' mis: ', misc," res: ", res)
        return res ? String(Math.round(res)) : '0';
    }
    const UpdateBgColor = (v1 = '$0', v2 = '$0', v3 = '$0', v4 = '$0', v5 = "$0", v6 = '$0', v7 = '$0', type = '') => {
        const findMinMax = (array) => {
            if (array.length === 0) {
                return null;
            }
            let min = array[0];
            let max = array[0];
            for (let i = 1; i < array.length; i++) {
                if (array[i] < min) {
                    min = array[i];
                }
                if (array[i] > max) {
                    max = array[i];
                }
            }
            return { min, max };
        }
        const findMiddleValue = (array) => {
            const { min, max } = findMinMax(array);
            if (min === null || max === null) {
                return null;
            }
            return (min + max) / 2;
        }
        const values = [v1, v2, v3, v4, v5, v6, v7].map(x => { return Number(x.replace('$', '').replaceAll(',', '')) });
        const { min, max } = findMinMax(values);
        const middleValue = findMiddleValue(values);

        const getColor = (value, minValue, maxValue, mid) => {
            // Define hue ranges for green and yellow
            const minHue = type === 'dealer' ? 45.18 : 105; // Starting hue for green
            const maxHue = type === 'dealer' ? 105 : 45.18; // Starting hue for yellow
            const initialColor = type === 'dealer' ? 105 : 45.18;
            const hueRange = maxHue - minHue;
            const normalizedValue = (value - minValue) / (maxValue - minValue);
            const hue = minHue + (hueRange * normalizedValue);
            const saturation = '80%';
            const lightness = '50%';

            return `hsl(${Number.isNaN(hue) ? initialColor : hue}, ${saturation}, ${lightness})`;
        };
        let colors = values.map(x => {
            return getColor(x, min, max, middleValue);
        })
        let [bColorRegion, bColorUnitTonnage, bColorAcHp, bColorfrnAh, bColorBrand, bColorMargin, bColorLast] = [...colors];
        switch (type) {
            case 'before':
                setBeforeColorRegion(bColorRegion);
                setBeforeColorUnitTonnage(bColorUnitTonnage);
                setBeforeColorAcHp(bColorAcHp);
                setBeforeColorfrnAh(bColorfrnAh);
                setBeforeColorBrand(bColorBrand);
                setBeforeColorMargin(bColorMargin);
                setBeforeColorLast(bColorLast);
                break;
            case 'after':
                setAfterColorRegion(bColorRegion);
                setAfterColorUnitTonnage(bColorUnitTonnage);
                setAfterColorAcHp(bColorAcHp);
                setAfterColorfrnAh(bColorfrnAh);
                setAfterColorBrand(bColorBrand);
                setAfterColorMargin(bColorMargin);
                setAfterColorLast(bColorLast);
                break;
            case 'dealer':
                setMarginDealerColorRegion(bColorRegion);
                setMarginDealerColorUnitTonnage(bColorUnitTonnage);
                setMarginDealerColorAcHp(bColorAcHp);
                setMarginDealerColorfrnAh(bColorfrnAh);
                setMarginDealerColorBrand(bColorBrand);
                setMarginDealerColorMargin(bColorMargin);
                setMarginDealerColorLast(bColorLast);
                break;
        }
    }
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    const handleClose = () => {
        setOpen(false);
        setIsMargin(false);
    };
    const handleLoadClose = () => {
        setOpenLoad(false);
    };
    const handleLoadOpen = () => {
        setOpenLoad(true);
    };
    const handleNonODModelRegion = (val) => {
        setNODModelRegion(val);
    };
    const handleNonODModelUnitTonnage = (val) => {
        setNODModelUnitTonnage(val);
    };
    const handleNonODModelAcHp = (val) => {
        setNODModelAcHp(val);
    };
    const handleNonODModelfrnAh = (val) => {
        setNODModelfrnAh(val);
    };
    const handleNonODModelBrand = (val) => {
        setNODModelBrand(val);
    };
    const handleNonODModelMargin = (val) => {
        setNODModelMargin(val);
    };
    const handleNonODModelLast = (val) => {
        setNODModelLast(val);
    };
    const handleNCoilRegion = (val) => {
        setNCoilRegion(val);
    };
    const handleNCoilUnitTonnage = (val) => {
        setNCoilUnitTonnage(val);
    };
    const handleNCoilAcHp = (val) => {
        setNCoilAcHp(val);
    };
    const handleNCoilfrnAh = (val) => {
        setNCoilfrnAh(val);
    };
    const handleNCoilBrand = (val) => {
        setNCoilBrand(val);
    };
    const handleNCoilMargin = (val) => {
        setNCoilMargin(val);
    };
    const handleNCoilLast = (val) => {
        setNCoilLast(val);
    };
    const handleNEquipRegion = (val) => {
        setNEquipRegion(val);
    };
    const handleNEquipUnitTonnage = (val) => {
        setNEquipUnitTonnage(val);
    };
    const handleNEquipAcHp = (val) => {
        setNEquipAcHp(val);
    };
    const handleNEquipfrnAh = (val) => {
        setNEquipfrnAh(val);
    };
    const handleNEquipBrand = (val) => {
        setNEquipBrand(val);
    };
    const handleNEquipMargin = (val) => {
        setNEquipMargin(val);
    };
    const handleNEquipLast = (val) => {
        setNEquipLast(val);
    };
    const downloadAsPDF = useReactToPrint({
        content: () => document.body,
    });

    return (
        <Container className='tableContainer' >
            {loading ? (
                <div className='loader'>
                    {/* <Typography>Loading...</Typography>
                    <CircularProgress style={{ marginTop: 20 }} /> */}
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                        // onClick={handleLoadClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            ) : (
                <Grid container style={{ padding: "0.5rem" }}>
                    <Grid item xs={12} spacing={3} className='instructionBox' style={{ justifyContent: 'space-between' }}>
                        <div  className='downloadPdf commonClassBtn' style={{ alignSelf: 'end' }}>
                            <p>
                                <Button ref={pdfBtn} color="primary" className='instructionBtn' style={{ width: '160px' }}
                                    onClick={() => {
                                        const lookref = lookupRef.current;
                                        const downloadbtn = pdfBtn.current;
                                        const videobtn = videoBtn.current;
                                        downloadbtn.style.display = 'none';
                                        videobtn.style.display = 'none';
                                        lookref.style.display = 'none';
                                        downloadAsPDF();
                                        downloadbtn.style.display = 'flex';
                                        videobtn.style.display = 'flex';
                                        lookref.style.display = 'table-row';
                                    }
                                    }
                                >
                                    DownLoad PDF
                                </Button>
                            </p>
                        </div>
                        <TableContainer  className='instructTable' component={Paper} style={{ marginBottom: "1rem", width: '40%' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' className='sTableHead' colSpan={8}>Split Ducted ASHP 25C IRA Tax Credit Qualifications</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {staticTableData.map((row, rowIndex) => (
                                        <TableRow key={rowIndex} className={rowIndex == 0 ? "sTableSubHead" : "sTableRows"}>
                                            {row.map((cell, colIndex) => (
                                                <TableCell key={colIndex} align="center" className={colIndex == 1 ? "sTableHeadcol tableFields" : "sTableCols tableFields"}>
                                                    {cell}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='instructImage' style={{ width: '20%' }}>
                            <img src={InstructImage} alt="Your Image" style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className='instructVideo commonClassBtn' style={{ alignSelf: 'end' }}>
                            <p>
                                <Button ref={videoBtn} onClick={handleOpenModal} color="primary" className='instructionBtn' style={{ width: '160px' }}>
                                    Instruction Video
                                </Button>
                            </p>
                            <VideoModal open={openModal} handleClose={handleCloseModal} />
                        </div>
                    </Grid>
                    <Grid item xs={12} ref={pdfContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className='inputTable'>
                                <TableContainer component={Paper} className='tableContainer'>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" colSpan={1} style={{ background: "transparent" }}></TableCell>
                                                {inputTableData[0].map((header, index) => (
                                                    /* <TableCell key={index} align="center" colSpan={6} > */
                                                    <TableCell key={index} align="center" colSpan={6} style={header ? {} : { border: 'unset' }} >
                                                        {header}
                                                    </TableCell>
                                                ))}
                                                <TableCell align="center" colSpan={1} style={{ background: "transparent", border: 'unset' }}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {inputTableData[1].map((header, index) => (
                                                    <TableCell key={index} align="center" style={header ? { border: 'inherit' } : { border: "unset" }}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className='firstRow'>
                                                {firstTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData?.Main_User_Input.Data_Updated}
                                                                disabled
                                                                className='tableFields'
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={{ background: 'transparent' }}
                                                                className='tableSelect'
                                                                value={mainRegion}
                                                                onChange={(e) => { handleMainRegion(e.target.value) }}
                                                            >
                                                                {(firstTData?.Main_User_Input.Region).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={{ background: 'transparent' }}
                                                                className='tableSelect'
                                                                value={mainUnitTonnage}
                                                                onChange={(e) => { handleMainUnitTonnage(e.target.value) }}
                                                            >
                                                                {(firstTData?.Main_User_Input.Unit_Tonnage).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={{ background: 'transparent' }}
                                                                className='tableSelect'
                                                                value={mainAcHp}
                                                                onChange={(e) => { handleMainAcHp(e.target.value) }}
                                                            >
                                                                {(firstTData?.Main_User_Input.Ac_Hp).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={{ background: 'transparent' }}
                                                                className='tableSelect'
                                                                value={mainfrnAh}
                                                                onChange={(e) => { handleMainFrnAh(e.target.value) }}
                                                            >
                                                                {(firstTData?.Main_User_Input.Frn_Ah).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={{ background: 'transparent' }}
                                                                className='tableSelect'
                                                                value={mainBrand}
                                                                onChange={(e) => { handleMainBrand(e.target.value) }}
                                                            >
                                                                {(firstTData?.Main_User_Input.Brand).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={mainMargin}
                                                                error={Number(mainMargin.replace('%', '')?.replaceAll(',', '')) < 0 || Number(mainMargin.replace('%', '')?.replaceAll(',', '')) > 100}
                                                                className='tableFields'
                                                                onChange={(e) => { handleMainMargin(e.target.value) }}
                                                            />
                                                        </TableCell>
                                                    </>
                                                )
                                                }
                                            </TableRow>
                                            <TableRow >
                                                <TableCell style={{ background: "#fff", padding: "0.5rem" }} className='dummySpace'></TableCell>
                                            </TableRow>
                                            <TableRow className='secondRow'>
                                                {firstTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <span>SEER2 Family</span>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData.Seer2_Family.Region}
                                                                disabled
                                                                className='tableFields tDisabled seerData'
                                                                style={{ background: '#0070c0', color: '#fff' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData.Seer2_Family.Unit_Tonnage}
                                                                disabled
                                                                className='tableFields tDisabled seerData'
                                                                style={{ background: '#0070c0', color: '#fff' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData.Seer2_Family.Ac_Hp}
                                                                disabled
                                                                className='tableFields tDisabled seerData'
                                                                style={{ background: '#0070c0', color: '#fff' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData.Seer2_Family.Frn_Ah}
                                                                disabled
                                                                className='tableFields tDisabled seerData'
                                                                style={{ background: '#0070c0', color: '#fff' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData.Seer2_Family.Brand}
                                                                disabled
                                                                className='tableFields tDisabled seerData'
                                                                style={{ background: '#0070c0', color: '#fff' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData.Seer2_Family.Desired_Gross_Margin}
                                                                disabled
                                                                className='tableFields tDisabled seerData'
                                                                style={{ background: '#0070c0', color: '#fff' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={firstTData.Seer2_Family.Last_Column}
                                                                disabled
                                                                className='tableFields tDisabled seerData'
                                                                style={{ background: '#0070c0', color: '#fff' }}
                                                            />
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                            <TableRow className='ThirdRow'>
                                                {secondTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <span>Product Family</span>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(productRegion == null || productRegion == false || productRegion == '') ? { background: 'red' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={productRegion}
                                                                onChange={(e) => { handleProductRegion(e.target.value) }}

                                                            >
                                                                {(secondTData?.Product_Family?.Region).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(productUnitTonnage == null || productUnitTonnage == false || productUnitTonnage == '') ? { background: 'red' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={productUnitTonnage}
                                                                onChange={(e) => { handleProductUnitTonnage(e.target.value) }}
                                                            >
                                                                {(secondTData?.Product_Family.Unit_Tonnage).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(productAcHp == null || productAcHp == false || productAcHp == '') ? { background: 'red' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={productAcHp}
                                                                onChange={(e) => { handleProductAcHp(e.target.value) }}
                                                            >
                                                                {(secondTData?.Product_Family.Ac_Hp).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(productfrnAh == null || productfrnAh == false || productfrnAh == '') ? { background: 'red' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={productfrnAh}
                                                                onChange={(e) => { handleProductFrnAh(e.target.value) }}
                                                            >
                                                                {(secondTData?.Product_Family.Frn_Ah).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(productBrand == null || productBrand == false || productBrand == '') ? { background: 'red' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={productBrand}
                                                                onChange={(e) => { handleProductBrand(e.target.value) }}
                                                            >
                                                                {(secondTData?.Product_Family.Brand).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(productMargin == null || productMargin == false || productMargin == '') ? { background: 'red' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={productMargin}
                                                                onChange={(e) => { handleProductMargin(e.target.value) }}
                                                            >
                                                                {(secondTData?.Product_Family.Desired_Gross_Margin).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(productLast == null || productLast == false || productLast == '') ? { background: 'red' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={productLast}
                                                                onChange={(e) => { handleProductLast(e.target.value) }}
                                                            >
                                                                {(secondTData?.Product_Family.Last_Column).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                            <TableRow className='FourthRow'>
                                                {secondTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <span>Qualifying OD Model</span>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(oDModelRegion == null || oDModelRegion == false || oDModelRegion == '') ? { background: 'red' } : (oDModelRegion == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={oDModelRegion}
                                                                onChange={(e) => { handleODModelRegion(e.target.value) }}
                                                            >
                                                                {odFilterDataRegion && (odFilterDataRegion).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(oDModelUnitTonnage == null || oDModelUnitTonnage == false || oDModelUnitTonnage == '') ? { background: 'red' } : (oDModelUnitTonnage == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={oDModelUnitTonnage}
                                                                onChange={(e) => { handleODModelUnitTonnage(e.target.value) }}
                                                            >
                                                                {odFilterDataUnitTonnage && (odFilterDataUnitTonnage).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(oDModelAcHp == null || oDModelAcHp == false || oDModelAcHp == '') ? { background: 'red' } : (oDModelAcHp == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={oDModelAcHp}
                                                                onChange={(e) => { handleODModelAcHp(e.target.value) }}
                                                            >
                                                                {odFilterDataAcHP && (odFilterDataAcHP).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(oDModelfrnAh == null || oDModelfrnAh == false || oDModelfrnAh == '') ? { background: 'red' } : (oDModelfrnAh == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={oDModelfrnAh}
                                                                onChange={(e) => { handleODModelFrnAh(e.target.value) }}
                                                            >
                                                                {odFilterDataFrnAh && (odFilterDataFrnAh).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(oDModelBrand == null || oDModelBrand == false || oDModelBrand == '') ? { background: 'red' } : (oDModelBrand == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={oDModelBrand}
                                                                onChange={(e) => { handleODModelBrand(e.target.value) }}
                                                            >
                                                                {odFilterDataBrand && (odFilterDataBrand).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(oDModelMargin == null || oDModelMargin == false || oDModelMargin == '') ? { background: 'red' } : (oDModelMargin == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={oDModelMargin}
                                                                onChange={(e) => { handleODModelMargin(e.target.value) }}
                                                            >
                                                                {odFilterDataMargin && (odFilterDataMargin).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(oDModelLast == null || oDModelLast == false || oDModelLast == '') ? { background: 'red' } : (oDModelLast == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={oDModelLast}
                                                                onChange={(e) => { handleODModelLast(e.target.value) }}
                                                            >
                                                                {odFilterDataLast && (odFilterDataLast).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                            <TableRow className='fifthRow'>
                                                <TableCell align="center" class='selectColumn'>
                                                    <span>Non Qualifying OD Model</span>
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nODModelRegion}
                                                        disabled={(oDModelRegion == 'N/A' && productRegion !== 'N/A') ? false : true}
                                                        onChange={(e) => { handleNonODModelRegion(e.target.value) }}

                                                        className={(oDModelRegion == 'N/A' && productRegion !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(oDModelRegion == 'N/A' && productRegion !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nODModelUnitTonnage}
                                                        disabled={(oDModelUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A') ? false : true}
                                                        onChange={(e) => { handleNonODModelUnitTonnage(e.target.value) }}
                                                        className={(oDModelUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(oDModelUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nODModelAcHp}
                                                        onChange={(e) => { handleNonODModelAcHp(e.target.value) }}
                                                        disabled={(oDModelAcHp == 'N/A' && productAcHp !== 'N/A') ? false : true}
                                                        className={(oDModelAcHp == 'N/A' && productAcHp !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(oDModelAcHp == 'N/A' && productAcHp !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nODModelfrnAh}
                                                        onChange={(e) => { handleNonODModelfrnAh(e.target.value) }}
                                                        disabled={(oDModelfrnAh == 'N/A' && productfrnAh !== 'N/A') ? false : true}
                                                        className={(oDModelfrnAh == 'N/A' && productfrnAh !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(oDModelfrnAh == 'N/A' && productfrnAh !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nODModelBrand}
                                                        onChange={(e) => { handleNonODModelBrand(e.target.value) }}
                                                        disabled={(oDModelBrand == 'N/A' && productBrand !== 'N/A') ? false : true}
                                                        className={(oDModelBrand == 'N/A' && productBrand !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(oDModelBrand == 'N/A' && productBrand !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nODModelMargin}
                                                        onChange={(e) => { handleNonODModelMargin(e.target.value) }}
                                                        disabled={(oDModelMargin == 'N/A' && productMargin !== 'N/A') ? false : true}
                                                        className={(oDModelMargin == 'N/A' && productMargin !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(oDModelMargin == 'N/A' && productMargin !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nODModelLast}
                                                        onChange={(e) => { handleNonODModelLast(e.target.value) }}
                                                        disabled={(oDModelLast == 'N/A' && productLast !== 'N/A') ? false : true}
                                                        className={(oDModelLast == 'N/A' && productLast !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(oDModelLast == 'N/A' && productLast !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='sixthRow'>
                                                {firstTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <span># of Qualifying Ratings with OD</span>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={qRODRegion}
                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={qRODUnitTonnage}
                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={qRODAcHp}
                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={qRODfrnAh}
                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={qRODBrand}
                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={qRODMargin}
                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={qRODLast}
                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                            <TableRow className='seventhRow'>
                                                {firstTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <span>Qualifying Indoor Equipment</span>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                     
                                                            <Select
                                                                style={(equipRegion == null || equipRegion == false || equipRegion == '') ? { background: 'red' } : (equipRegion == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={equipRegion[0]=='N/A'? equipRegion[0] : equipRegion}
                                                                onChange={(e) => { handleEquipRegion(e.target.value) }}
                                                            >
                                                                {odTDataRegion && (odTDataRegion?.Qualifying_Indoor_Equipment).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option} style={(lookUpValidate['11DigitModel'].filter(x => option?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : { background: '#fff' }}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                       
                                                            <Select
                                                                style={(equipUnitTonnage == null || equipUnitTonnage == false || equipUnitTonnage == '') ? { background: 'red' } : (equipUnitTonnage == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={equipUnitTonnage[0]=='N/A'? equipUnitTonnage[0]: equipUnitTonnage}
                                                                onChange={(e) => { handleEquipUnitTonnage(e.target.value) }}
                                                            >
                                                                {odTDataUnitTonnage && (odTDataUnitTonnage?.Qualifying_Indoor_Equipment).map((option, optionIndex) => (

                                                                    <MenuItem key={optionIndex} value={option} style={(lookUpValidate['11DigitModel'].filter(x => option?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : { background: '#fff' }}>
                                                                        {option}
                                                                    </MenuItem>

                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(equipAcHp == null || equipAcHp == false || equipAcHp == '') ? { background: 'red' } : (equipAcHp == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={equipAcHp[0]=='N/A'? equipAcHp[0]: equipAcHp}
                                                                onChange={(e) => { handleEquipAcHp(e.target.value) }}
                                                            >
                                                                {odTDataAcHP && (odTDataAcHP?.Qualifying_Indoor_Equipment).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option} style={(lookUpValidate['11DigitModel'].filter(x => option?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : { background: '#fff' }}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(equipfrnAh == null || equipfrnAh == false || equipfrnAh == '') ? { background: 'red' } : (equipfrnAh == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={equipfrnAh[0]=='N/A'? equipfrnAh[0]: equipfrnAh}
                                                                onChange={(e) => { handleEquipFrnAh(e.target.value) }}
                                                            >
                                                                {odTDataFrnAh && (odTDataFrnAh?.Qualifying_Indoor_Equipment).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option} style={(lookUpValidate['11DigitModel'].filter(x => option?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : { background: '#fff' }}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(equipBrand == null || equipBrand == false || equipBrand == '') ? { background: 'red' } : (equipBrand == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={equipBrand[0]=='N/A'? equipBrand[0]: equipBrand}
                                                                onChange={(e) => { handleEquipBrand(e.target.value) }}
                                                            >
                                                                {odTDataBrand && (odTDataBrand?.Qualifying_Indoor_Equipment).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option} style={(lookUpValidate['11DigitModel'].filter(x => option?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : { background: '#fff' }}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(equipMargin == null || equipMargin == false || equipMargin == '') ? { background: 'red' } : (equipMargin == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={equipMargin[0]=='N/A'? equipMargin[0]: equipMargin}
                                                                onChange={(e) => { handleEquipMargin(e.target.value) }}
                                                            >
                                                                {odTDataMargin && (odTDataMargin?.Qualifying_Indoor_Equipment).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option} style={(lookUpValidate['11DigitModel'].filter(x => option?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : { background: '#fff' }}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(equipLast == null || equipLast == false || equipLast == '') ? { background: 'red' } : (equipLast == 'N/A') ? { background: '#bfbfbf' } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={equipLast[0]=='N/A'? equipLast[0]: equipLast}
                                                                onChange={(e) => { handleEquipLast(e.target.value) }}
                                                            >
                                                                {odTDataLast && (odTDataLast?.Qualifying_Indoor_Equipment).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option} style={(lookUpValidate['11DigitModel'].filter(x => option?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : { background: '#fff' }}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                            <TableRow className='eighthRow'>
                                                <TableCell align="center" class='selectColumn'>
                                                    <span>Non Qualifying Indoor Equipment</span>
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        value={nEquipRegion}
                                                        onChange={(e) => { handleNEquipRegion(e.target.value) }}
                                                        disabled={(equipRegion == 'N/A' && productRegion !== 'N/A') ? false : true}
                                                        className={(equipRegion == 'N/A' && productRegion !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(equipRegion == 'N/A' && productRegion !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nEquipUnitTonnage}
                                                        onChange={(e) => { handleNEquipUnitTonnage(e.target.value) }}
                                                        disabled={(equipUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A') ? false : true}
                                                        className={(equipUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(equipUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nEquipAcHp}
                                                        onChange={(e) => { handleNEquipAcHp(e.target.value) }}
                                                        disabled={(equipAcHp == 'N/A' && productAcHp !== 'N/A') ? false : true}
                                                        className={(equipAcHp == 'N/A' && productAcHp !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(equipAcHp == 'N/A' && productAcHp !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nEquipfrnAh}
                                                        onChange={(e) => { handleNEquipfrnAh(e.target.value) }}
                                                        disabled={(equipfrnAh == 'N/A' && productfrnAh !== 'N/A') ? false : true}
                                                        className={(equipfrnAh == 'N/A' && productfrnAh !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(equipfrnAh == 'N/A' && productfrnAh !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nEquipBrand}
                                                        onChange={(e) => { handleNEquipBrand(e.target.value) }}
                                                        disabled={(equipBrand == 'N/A' && productBrand !== 'N/A') ? false : true}
                                                        className={(equipBrand == 'N/A' && productBrand !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(equipBrand == 'N/A' && productBrand !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nEquipMargin}
                                                        onChange={(e) => { handleNEquipMargin(e.target.value) }}
                                                        disabled={(equipMargin == 'N/A' && productMargin !== 'N/A') ? false : true}
                                                        className={(equipMargin == 'N/A' && productMargin !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(equipMargin == 'N/A' && productMargin !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nEquipLast}
                                                        onChange={(e) => { handleNEquipLast(e.target.value) }}
                                                        disabled={(equipLast == 'N/A' && productLast !== 'N/A') ? false : true}
                                                        className={(equipLast == 'N/A' && productLast !== 'N/A') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(equipLast == 'N/A' && productLast !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ninthRow'>
                                                {firstTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <span>Qualifying Coil (If Applicable)</span>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                       
                                                            <Select
                                                                style={(coilRegion == null || coilRegion == false || coilRegion == '') ? { background: 'red' } : (coilRegion == 'N/A') ? { background: '#bfbfbf',color:"#000" } : { background: "transparent",color:"#000" }}
                                                                className='tableSelect'
                                                                value={coilRegion}
                                                                onChange={(e) => { handleCoilRegion(e.target.value) }}
                                                                disabled={oDModelRegion =='N/A'? true: false}
                                                            >
                                                                {coilTDataRegion && (coilTDataRegion?.Qualifying_Coil).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(coilUnitTonnage == null || coilUnitTonnage == false || coilUnitTonnage == '') ? { background: 'red' } : (coilUnitTonnage == 'N/A') ? { background: '#bfbfbf',color:"#000" } : { background: "transparent",color:"#000"}}
                                                                className='tableSelect'
                                                                value={coilUnitTonnage}
                                                                onChange={(e) => { handleCoilUnitTonnage(e.target.value) }}
                                                                disabled={oDModelUnitTonnage =='N/A'? true: false}
                                                            >
                                                                {coilTDataUnitTonnage && (coilTDataUnitTonnage?.Qualifying_Coil).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(coilAcHp == null || coilAcHp == false || coilAcHp == '') ? { background: 'red' } : (coilAcHp == 'N/A') ? { background: '#bfbfbf',color:"#000" } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={coilAcHp}
                                                                onChange={(e) => { handleCoilAcHp(e.target.value) }}
                                                                disabled={oDModelAcHp =='N/A'? true: false}
                                                            >
                                                                {coilTDataAcHP && (coilTDataAcHP?.Qualifying_Coil).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(coilfrnAh == null || coilfrnAh == false || coilfrnAh == '') ? { background: 'red' } : (coilfrnAh == 'N/A') ? { background: '#bfbfbf',color:"#000" } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={coilfrnAh}
                                                                onChange={(e) => { handleCoilFrnAh(e.target.value) }}
                                                                 disabled={oDModelfrnAh =='N/A'? true: false}
                                                            >
                                                                {coilTDataFrnAh && (coilTDataFrnAh?.Qualifying_Coil).map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(coilBrand == null || coilBrand == false || coilBrand == '') ? { background: 'red' } : (coilBrand == 'N/A') ? { background: '#bfbfbf',color:"#000" } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={coilBrand}
                                                                onChange={(e) => { handleCoilBrand(e.target.value) }}
                                                                 disabled={oDModelBrand =='N/A'? true: false}
                                                            >
                                                                {coilTDataBrand && (coilTDataBrand?.Qualifying_Coil).map((option, optionIndex) => (

                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>

                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(coilMargin == null || coilMargin == false || coilMargin == '') ? { background: 'red' } : (coilMargin == 'N/A') ? { background: '#bfbfbf',color:"#000" } : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={coilMargin}
                                                                onChange={(e) => { handleCoilMargin(e.target.value) }}
                                                                 disabled={oDModelMargin =='N/A'? true: false}
                                                            >
                                                                {coilTDataMargin && (coilTDataMargin?.Qualifying_Coil).map((option, optionIndex) => (

                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>

                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <Select
                                                                style={(coilLast == null || coilLast == false || coilLast == '') ? { background: 'red' } : (coilLast == 'N/A') ? { background: '#bfbfbf' ,color:"#000"} : { background: "transparent" }}
                                                                className='tableSelect'
                                                                value={coilLast}
                                                                onChange={(e) => { handleCoilLast(e.target.value) }}
                                                                 disabled={oDModelLast =='N/A'? true: false}
                                                            >
                                                                {coilTDataLast && (coilTDataLast?.Qualifying_Coil).map((option, optionIndex) => (

                                                                    <MenuItem key={optionIndex} value={option}>
                                                                        {option}
                                                                    </MenuItem>

                                                                ))}
                                                            </Select>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                            <TableRow className='tenthRow'>
                                                <TableCell align="center" class='selectColumn'>
                                                    <span>Non Qualifying Coil (If Applicable)</span>
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nCoilRegion}
                                                        onChange={(e) => { handleNCoilRegion(e.target.value) }}
                                                        disabled={(coilRegion == 'N/A' && productRegion !== 'N/A' && mainfrnAh == 'FRN') ? false : true}
                                                        className={(coilRegion == 'N/A' && productRegion !== 'N/A' && mainfrnAh == 'FRN') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(coilRegion == 'N/A' && productRegion !== 'N/A' && mainfrnAh == 'FRN') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nCoilUnitTonnage}
                                                        onChange={(e) => { handleNCoilUnitTonnage(e.target.value) }}
                                                        disabled={(coilUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A' && mainfrnAh == 'FRN') ? false : true}
                                                        className={(coilUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A' && mainfrnAh == 'FRN') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(coilUnitTonnage == 'N/A' && productUnitTonnage !== 'N/A' && mainfrnAh == 'FRN') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nCoilAcHp}
                                                        onChange={(e) => { handleNCoilAcHp(e.target.value) }}
                                                        disabled={(coilAcHp == 'N/A' && productAcHp !== 'N/A' && mainfrnAh == 'FRN') ? false : true}
                                                        className={(coilAcHp == 'N/A' && productAcHp !== 'N/A' && mainfrnAh == 'FRN') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(coilAcHp == 'N/A' && productAcHp !== 'N/A' && mainfrnAh == 'FRN') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nCoilfrnAh}
                                                        onChange={(e) => { handleNCoilfrnAh(e.target.value) }}
                                                        disabled={(coilfrnAh == 'N/A' && productfrnAh !== 'N/A' && mainfrnAh == 'FRN') ? false : true}
                                                        className={(coilfrnAh == 'N/A' && productfrnAh !== 'N/A' && mainfrnAh == 'FRN') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(coilfrnAh == 'N/A' && productfrnAh !== 'N/A' && mainfrnAh == 'FRN') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nCoilBrand}
                                                        onChange={(e) => { handleNCoilBrand(e.target.value) }}
                                                        disabled={(coilBrand == 'N/A' && productBrand !== 'N/A' && mainfrnAh == 'FRN') ? false : true}
                                                        className={(coilBrand == 'N/A' && productBrand !== 'N/A' && mainfrnAh == 'FRN') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(coilBrand == 'N/A' && productBrand !== 'N/A' && mainfrnAh == 'FRN') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nCoilMargin}
                                                        onChange={(e) => { handleNCoilMargin(e.target.value) }}
                                                        disabled={(coilMargin == 'N/A' && productMargin !== 'N/A' && mainfrnAh == 'FRN') ? false : true}
                                                        className={(coilMargin == 'N/A' && productMargin !== 'N/A' && mainfrnAh == 'FRN') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(coilMargin == 'N/A' && productMargin !== 'N/A' && mainfrnAh == 'FRN') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='selectColumn'>
                                                    <TextField
                                                        type="text"
                                                        value={nCoilLast}
                                                        onChange={(e) => { handleNCoilLast(e.target.value) }}
                                                        disabled={(coilLast == 'N/A' && productLast !== 'N/A' && mainfrnAh == 'FRN') ? false : true}
                                                        className={(coilLast == 'N/A' && productLast !== 'N/A' && mainfrnAh == 'FRN') ? 'tableFields ' : 'tableFields tDisabled'}
                                                        style={(coilLast == 'N/A' && productLast !== 'N/A' && mainfrnAh == 'FRN') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='eleventhRow'>
                                                {firstTData && (
                                                    <>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <span>AHRI Certification Number</span>
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={aHRIRegion == "" ? "N/A" : aHRIRegion}

                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={aHRIUnitTonnage == "" ? "N/A" : aHRIUnitTonnage}

                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={aHRIAcHp == "" ? 'N/A' : aHRIAcHp}

                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={aHRIfrnAh == "" ? "N/A" : aHRIfrnAh}

                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={aHRIBrand == "" ? "N/A" : aHRIBrand}

                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={aHRIMargin == "" ? "N/A" : aHRIMargin}

                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center" class='selectColumn'>
                                                            <TextField
                                                                type="text"
                                                                value={aHRILast == "" ? "N/A" : aHRILast}

                                                                className='tableFields tDisabled'
                                                                style={{ background: '#bfbfbf' }}
                                                            />
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>

                                            <TableRow >
                                                <TableCell style={{ background: "#fff", padding: "0.5rem" }} className='dummySpace'></TableCell>
                                            </TableRow>
                                            <TableRow className='firstInputRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>Equipment Cost (Actual)</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipCostRegion}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleEquipCostRegion(e.target.value) }}
                                                        error={Number(equipCostRegion.replace('$', '')?.replaceAll(',', '')) < 0 || Number(equipCostRegion.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productRegion == 'N/A') ? true : false}
                                                        style={(productRegion !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipCostUnitTonnage}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleEquipCostUnitTonnage(e.target.value) }}
                                                        error={Number(equipCostUnitTonnage.replace('$', '')?.replaceAll(',', '')) < 0 || Number(equipCostUnitTonnage.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productUnitTonnage == 'N/A') ? true : false}
                                                        style={(productUnitTonnage !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipCostAcHp}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleEquipCostAcHp(e.target.value) }}
                                                        error={Number(equipCostAcHp.replace('$', '')?.replaceAll(',', '')) < 0 || Number(equipCostAcHp.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productAcHp == 'N/A') ? true : false}
                                                        style={(productAcHp !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipCostfrnAh}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleEquipCostfrnAh(e.target.value) }}
                                                        error={Number(equipCostfrnAh.replace('$', '')?.replaceAll(',', '')) < 0 || Number(equipCostfrnAh.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productfrnAh == 'N/A') ? true : false}
                                                        style={(productfrnAh !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipCostBrand}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleEquipCostBrand(e.target.value) }}
                                                        error={Number(equipCostBrand.replace('$', '')?.replaceAll(',', '')) < 0 || Number(equipCostBrand.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productBrand == 'N/A') ? true : false}
                                                        style={(productBrand !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipCostMargin}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleEquipCostMargin(e.target.value) }}
                                                        error={Number(equipCostMargin.replace('$', '')?.replaceAll(',', '')) < 0 || Number(equipCostMargin.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productMargin == 'N/A') ? true : false}
                                                        style={(productMargin !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipCostLast}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleEquipCostLast(e.target.value) }}
                                                        error={Number(equipCostLast.replace('$', '')?.replaceAll(',', '')) < 0 || Number(equipCostLast.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productLast == 'N/A') ? true : false}
                                                        style={(productLast !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='newInputRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>Miscellaneous (Actual)</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={miscellaneousRegion}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleMiscellaneousRegion(e.target.value) }}
                                                        error={Number(miscellaneousRegion.replace('$', '')?.replaceAll(',', '')) < 0 || Number(miscellaneousRegion.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productRegion == 'N/A') ? true : false}
                                                        style={(productRegion !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={miscellaneousUnitTonnage}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleMiscellaneousUnitTonnage(e.target.value) }}
                                                        error={Number(miscellaneousUnitTonnage.replace('$', '')?.replaceAll(',', '')) < 0 || Number(miscellaneousUnitTonnage.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productUnitTonnage == 'N/A') ? true : false}
                                                        style={(productUnitTonnage !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={miscellaneousAcHp}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleMiscellaneousAcHp(e.target.value) }}
                                                        error={Number(miscellaneousAcHp.replace('$', '')?.replaceAll(',', '')) < 0 || Number(miscellaneousAcHp.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productAcHp == 'N/A') ? true : false}
                                                        style={(productAcHp !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={miscellaneousfrnAh}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleMiscellaneousfrnAh(e.target.value) }}
                                                        error={Number(miscellaneousfrnAh.replace('$', '')?.replaceAll(',', '')) < 0 || Number(miscellaneousfrnAh.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productfrnAh == 'N/A') ? true : false}
                                                        style={(productfrnAh !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={miscellaneousBrand}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleMiscellaneousBrand(e.target.value) }}
                                                        error={Number(miscellaneousBrand.replace('$', '')?.replaceAll(',', '')) < 0 || Number(miscellaneousBrand.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productBrand == 'N/A') ? true : false}
                                                        style={(productBrand !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={miscellaneousMargin}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleMiscellaneousMargin(e.target.value) }}
                                                        error={Number(miscellaneousMargin.replace('$', '')?.replaceAll(',', '')) < 0 || Number(miscellaneousMargin.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productMargin == 'N/A') ? true : false}
                                                        style={(productMargin !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={miscellaneousLast}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleMiscellaneousLast(e.target.value) }}
                                                        error={Number(miscellaneousLast.replace('$', '')?.replaceAll(',', '')) < 0 || Number(miscellaneousLast.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productLast == 'N/A') ? true : false}
                                                        style={(productLast !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='secondInputRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>Labor and Material Cost (Actual)</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={lMCostRegion}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLMCostRegion(e.target.value) }}
                                                        error={Number(lMCostRegion.replace('$', '')?.replaceAll(',', '')) < 0 || Number(lMCostRegion.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productRegion == 'N/A') ? true : false}
                                                        style={(productRegion !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={lMCostUnitTonnage}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLMCostUnitTonnage(e.target.value) }}
                                                        error={Number(lMCostUnitTonnage.replace('$', '')?.replaceAll(',', '')) < 0 || Number(lMCostUnitTonnage.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productUnitTonnage == 'N/A') ? true : false}
                                                        style={(productUnitTonnage !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={lMCostAcHp}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLMCostAcHp(e.target.value) }}
                                                        error={Number(lMCostAcHp.replace('$', '')?.replaceAll(',', '')) < 0 || Number(lMCostAcHp.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productAcHp == 'N/A') ? true : false}
                                                        style={(productAcHp !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={lMCostfrnAh}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLMCostfrnAh(e.target.value) }}
                                                        error={Number(lMCostfrnAh.replace('$', '')?.replaceAll(',', '')) < 0 || Number(lMCostfrnAh.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productfrnAh == 'N/A') ? true : false}
                                                        style={(productfrnAh !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={lMCostBrand}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLMCostBrand(e.target.value) }}
                                                        error={Number(lMCostBrand.replace('$', '')?.replaceAll(',', '')) < 0 || Number(lMCostBrand.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productBrand == 'N/A') ? true : false}
                                                        style={(productBrand !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={lMCostMargin}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLMCostMargin(e.target.value) }}
                                                        error={Number(lMCostMargin.replace('$', '')?.replaceAll(',', '')) < 0 || Number(lMCostMargin.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productMargin == 'N/A') ? true : false}
                                                        style={(productMargin !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={lMCostLast}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLMCostLast(e.target.value) }}
                                                        error={Number(lMCostLast.replace('$', '')?.replaceAll(',', '')) < 0 || Number(lMCostLast.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productLast == 'N/A') ? true : false}
                                                        style={(productLast !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='ThirdInputRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>Margin Dollars</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerRegion}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerUnitTonnage}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" }}
                                                    />

                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerAcHp}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerfrnAh}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerBrand}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerMargin}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerLast}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='fourthInputRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>Local Rebate</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={localeRebateRegion}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLocaleRebateRegion(e.target.value) }}
                                                        error={Number(localeRebateRegion.replace('$', '')?.replaceAll(',', '')) < 0 || Number(localeRebateRegion.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productRegion == 'N/A') ? true : false}
                                                        style={(productRegion !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={localeRebateUnitTonnage}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLocaleRebateUnitTonnage(e.target.value) }}
                                                        error={Number(localeRebateUnitTonnage.replace('$', '')?.replaceAll(',', '')) < 0 || Number(localeRebateUnitTonnage.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productUnitTonnage == 'N/A') ? true : false}
                                                        style={(productUnitTonnage !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={localeRebateAcHp}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLocaleRebateAcHp(e.target.value) }}
                                                        error={Number(localeRebateAcHp.replace('$', '')?.replaceAll(',', '')) < 0 || Number(localeRebateAcHp.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productAcHp == 'N/A') ? true : false}
                                                        style={(productAcHp !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={localeRebatefrnAh}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLocaleRebatefrnAh(e.target.value) }}
                                                        error={Number(localeRebatefrnAh.replace('$', '')?.replaceAll(',', '')) < 0 || Number(localeRebatefrnAh.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productfrnAh == 'N/A') ? true : false}
                                                        style={(productfrnAh !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={localeRebateBrand}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLocaleRebateBrand(e.target.value) }}
                                                        error={Number(localeRebateBrand.replace('$', '')?.replaceAll(',', '')) < 0 || Number(localeRebateBrand.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productBrand == 'N/A') ? true : false}
                                                        style={(productBrand !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={localeRebateMargin}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLocaleRebateMargin(e.target.value) }}
                                                        error={Number(localeRebateMargin.replace('$', '')?.replaceAll(',', '')) < 0 || Number(localeRebateMargin.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productMargin == 'N/A') ? true : false}
                                                        style={(productMargin !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={localeRebateLast}
                                                        className='tableInputs tableFields'
                                                        onChange={(e) => { handleLocaleRebateLast(e.target.value) }}
                                                        error={Number(localeRebateLast.replace('$', '')?.replaceAll(',', '')) < 0 || Number(localeRebateLast.replace('$', '')?.replaceAll(',', '')) > 15000}
                                                        disabled={(productLast == 'N/A') ? true : false}
                                                        style={(productLast !== 'N/A') ? { background: '#fff' } : { background: '#bfbfbf' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='fifthInputRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>IRA Tax Credit: Outdoor {mainAcHp}</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={outDoorRegion}
                                                        className={(outDoorRegion == '$0') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(outDoorRegion == '$0') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={outDoorUnitTonnage}
                                                        className={(outDoorUnitTonnage == '$0') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(outDoorUnitTonnage == '$0') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />

                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={outDoorAcHp}
                                                        className={(outDoorAcHp == '$0') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(outDoorAcHp == '$0') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={outDoorfrnAh}
                                                        className={(outDoorfrnAh == '$0') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(outDoorfrnAh == '$0') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={outDoorBrand}
                                                        className={(outDoorBrand == '$0') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(outDoorBrand == '$0') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={outDoorMargin}
                                                        className={(outDoorMargin == '$0') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(outDoorMargin == '$0') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={outDoorLast}
                                                        className={(outDoorLast == '$0') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(outDoorLast == '$0') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='sixInputRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>IRA Tax Credit: Furnace</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipRegion=='N/A'? '$0': furnaceRegion}
                                                        className={(furnaceRegion == '$0' || equipRegion=='N/A') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(furnaceRegion == '$0'|| equipRegion=='N/A') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipUnitTonnage=='N/A'? '$0': furnaceUnitTonnage}
                                                        className={(furnaceUnitTonnage == '$0' || equipUnitTonnage=='N/A') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(furnaceUnitTonnage == '$0' || equipUnitTonnage=='N/A') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />

                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipAcHp=='N/A'? '$0': furnaceAcHp}
                                                        className={(furnaceAcHp == '$0' || equipAcHp=='N/A') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(furnaceAcHp == '$0' || equipAcHp=='N/A') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipfrnAh=='N/A'? '$0': furnacefrnAh}
                                                        className={(furnacefrnAh == '$0' || equipfrnAh=='N/A') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(furnacefrnAh == '$0' || equipfrnAh=='N/A') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipBrand=='N/A'? '$0': furnaceBrand}
                                                        className={(furnaceBrand == '$0' || equipBrand=='N/A') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(furnaceBrand == '$0' || equipBrand=='N/A') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipMargin=='N/A'? '$0': furnaceMargin}
                                                        className={(furnaceMargin == '$0' || equipMargin=='N/A') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(furnaceMargin == '$0' || equipMargin=='N/A') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={equipLast=='N/A'? '$0': furnaceLast}
                                                        className={(furnaceLast == '$0' || equipLast=='N/A') ? 'tableInputs tableFields tDisabled' : 'tableInputs tableFields tDisabled inputColor'}
                                                        disabled
                                                        style={(furnaceLast == '$0' || equipLast=='N/A') ? { background: '#bfbfbf', color: "#000", '-webkit-text-fill-color': "#000" } : { background: '#00b050', color: "#fff", '-webkit-text-fill-color': "#fff" }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell colSpan={8} style={{ background: "#fff", padding: "1rem", textAlign: 'center' }} className='dummySpace'></TableCell>
                                            </TableRow>
                                            <TableRow className='firstResultRow inputRows homeOwner'>
                                                <TableCell align="center" class='ipTable tableFields '>
                                                    <span>System Price to Homeowner <br />(<b>Before</b> Credits and Rebates)	</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={beforePriceRegion}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: beforeColorRegion }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={beforePriceUnitTonnage}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: beforeColorUnitTonnage }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={beforePriceAcHp}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: beforeColorAcHp }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={beforePricefrnAh}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: beforeColorfrnAh }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={beforePriceBrand}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: beforeColorBrand }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={beforePriceMargin}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: beforeColorMargin }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={beforePriceLast}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: beforeColorLast }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='secondResultRow inputRows homeOwner'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>System Price to Homeowner <br />(<b>After</b> Credits and Rebates)	</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={((equipCostRegion==''|| equipCostRegion == '$0')&&(lMCostRegion==''|| lMCostRegion == '$0')&&(miscellaneousRegion==''|| miscellaneousRegion == '$0')&&(localeRebateRegion==''|| localeRebateRegion == '$0')&& (equipRegion=='N/A')) ? '$0': afterPriceRegion }
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: afterColorRegion }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={((equipCostUnitTonnage==''|| equipCostUnitTonnage == '$0')&&(lMCostUnitTonnage==''|| lMCostUnitTonnage == '$0')&&(miscellaneousUnitTonnage==''|| miscellaneousUnitTonnage == '$0')&&(localeRebateUnitTonnage==''|| localeRebateUnitTonnage == '$0')&& (equipUnitTonnage=='N/A')) ? '$0':afterPriceUnitTonnage}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: afterColorUnitTonnage }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={((equipCostAcHp==''|| equipCostAcHp == '$0')&&(lMCostAcHp==''|| lMCostAcHp == '$0')&&(miscellaneousAcHp==''|| miscellaneousAcHp == '$0')&&(localeRebateAcHp==''|| localeRebateAcHp == '$0')&& (equipAcHp=='N/A')) ? '$0':afterPriceAcHp}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: afterColorAcHp }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={((equipCostfrnAh==''|| equipCostfrnAh == '$0')&&(lMCostfrnAh==''|| lMCostfrnAh == '$0')&&(miscellaneousfrnAh==''|| miscellaneousfrnAh == '$0')&&(localeRebatefrnAh==''|| localeRebatefrnAh == '$0')&& (equipfrnAh=='N/A')) ? '$0':afterPricefrnAh}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: afterColorfrnAh }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={((equipCostBrand==''|| equipCostBrand == '$0')&&(lMCostBrand==''|| lMCostBrand == '$0')&&(miscellaneousBrand==''|| miscellaneousBrand == '$0')&&(localeRebateBrand==''|| localeRebateBrand == '$0')&& (equipBrand=='N/A')) ? '$0':afterPriceBrand}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: afterColorBrand }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={((equipCostMargin==''|| equipCostMargin == '$0')&&(lMCostMargin==''|| lMCostMargin == '$0')&&(miscellaneousMargin==''|| miscellaneousMargin == '$0')&&(localeRebateMargin==''|| localeRebateMargin == '$0')&& (equipMargin=='N/A')) ? '$0':afterPriceMargin}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: afterColorMargin }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={((equipCostLast==''|| equipCostLast == '$0')&&(lMCostLast==''|| lMCostLast == '$0')&&(miscellaneousLast==''|| miscellaneousLast == '$0')&&(localeRebateLast==''|| localeRebateLast == '$0')&& (equipLast=='N/A')) ? '$0':afterPriceLast}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: afterColorLast }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow className='thirdResultRow inputRows'>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <span>Margin for Dealer</span>
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerRegion}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: marginDealerColorRegion }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerUnitTonnage}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: marginDealerColorUnitTonnage }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerAcHp}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: marginDealerColorAcHp }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerfrnAh}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: marginDealerColorfrnAh }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerBrand}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: marginDealerColorBrand }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerMargin}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: marginDealerColorMargin }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" class='ipTable tableFields'>
                                                    <TextField
                                                        type="text"
                                                        value={marginDealerLast}
                                                        className='tableInputs tableFields tDisabled'
                                                        disabled
                                                        style={{ background: marginDealerColorLast }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell colSpan={8} style={{ background: "#fff", padding: "1.5rem", textAlign: 'center', border: 'unset' }} className='dummySpace'></TableCell>
                                            </TableRow>
                                            <TableRow style={{ verticalAlign: 'top' }} ref={lookupRef}>
                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff", border: "none", color: 'fff', userSelect: 'none', padding: '0' }} >
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow >
                                                                <TableCell colSpan={8} style={{ background: "#fff", padding: "1.5rem", textAlign: 'center', border: 'unset' }} className='dummySpace'></TableCell>
                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell colSpan={8} style={{ background: "#fff", padding: "1.5rem", textAlign: 'center', border: 'unset' }} className='dummySpace'></TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell align="left" className='resultTable ' style={{ background: "#0070c0", color: "#fff", padding: '10px', border: '1px solid #000' }}>
                                                                    <span> Green Highlighted Furnaces to the RIGHT ------{'>'} <br />Qualify for the $600 Furnace Tax Credit</span>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell colSpan={8} style={{ background: "#fff", padding: "1.5rem", textAlign: 'center', border: 'unset' }} className='dummySpace'></TableCell>
                                                            </TableRow>
                                                            <TableRow >
                                                                <TableCell colSpan={8} style={{ background: "#fff", padding: "1.5rem", textAlign: 'center', border: 'unset' }} className='dummySpace'></TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#0070c0", color: '#fff' }} >
                                                                    Qualifying Indoor Models
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {((productRegion !== 'N/A' && qRODRegion !== 0) && lookUpValidate && lookUpRegion) ? (lookUpRegion?.map((row, rowIndex) => (
                                                                <TableRow key={rowIndex}>
                                                                    <TableCell key={rowIndex} align="center" className='resultTable tableFields' style={(lookUpValidate['11DigitModel'].filter(x => row?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : row ? { background: '#fff', border: '1px solid rgba(224, 224, 224, 1)' } : { border: 'unset' }}>
                                                                        {row}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )))
                                                                :
                                                                (
                                                                    <TableRow>
                                                                        <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff" }}>
                                                                            {'N/A'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#0070c0", color: '#fff' }} >
                                                                    Qualifying Indoor Models
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {((productUnitTonnage !== 'N/A' && qRODUnitTonnage !== 0) && lookUpValidate && lookUpUnitTonnage) ? (lookUpUnitTonnage?.map((row, rowIndex) => (
                                                                <>
                                                                    <TableRow key={rowIndex}>
                                                                        <TableCell key={rowIndex} align="center" className='resultTable tableFields' style={(lookUpValidate['11DigitModel'].filter(x => row?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : row ? { background: '#fff', border: '1px solid rgba(224, 224, 224, 1)' } : { border: 'unset' }}>
                                                                            {row}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>

                                                            ))
                                                            )
                                                                :
                                                                (
                                                                    <TableRow>
                                                                        <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff" }}>
                                                                            {'N/A'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#0070c0", color: '#fff' }} >
                                                                    Qualifying Indoor Models
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {((productAcHp !== 'N/A' && qRODAcHp !== 0) && lookUpValidate && lookUpAcHP) ? (lookUpAcHP?.map((row, rowIndex) => (
                                                                <TableRow key={rowIndex}>
                                                                    <TableCell key={rowIndex} align="center" className='resultTable tableFields' style={(lookUpValidate['11DigitModel'].filter(x => row?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : row ? { background: '#fff', border: '1px solid rgba(224, 224, 224, 1)' } : { border: 'unset' }}>
                                                                        {row}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                            )
                                                                :
                                                                (
                                                                    <TableRow>
                                                                        <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff" }}>
                                                                            {'N/A'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#0070c0", color: '#fff' }} >
                                                                    Qualifying Indoor Models
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {((productfrnAh !== 'N/A' && qRODfrnAh !== 0) && lookUpValidate && lookUpFrnAh) ? (lookUpFrnAh?.map((row, rowIndex) => (
                                                                <TableRow key={rowIndex}>
                                                                    <TableCell key={rowIndex} align="center" className='resultTable tableFields' style={(lookUpValidate['11DigitModel'].filter(x => row?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : row ? { background: '#fff', border: '1px solid rgba(224, 224, 224, 1)' } : { border: 'unset' }}>
                                                                        {row}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                            )
                                                                :
                                                                (
                                                                    <TableRow>
                                                                        <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff" }}>
                                                                            {'N/A'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#0070c0", color: '#fff' }} >
                                                                    Qualifying Indoor Models
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {((productBrand !== 'N/A' && qRODBrand !== 0) && lookUpValidate && lookUpBrand) ? (lookUpBrand?.map((row, rowIndex) => (

                                                                <TableRow key={rowIndex}>
                                                                    <TableCell key={rowIndex} align="center" className='resultTable tableFields' style={(lookUpValidate['11DigitModel'].filter(x => row?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : row ? { background: '#fff', border: '1px solid rgba(224, 224, 224, 1)' } : { border: 'unset' }}>
                                                                        {row}
                                                                    </TableCell>
                                                                </TableRow>

                                                            ))
                                                            )
                                                                :
                                                                (
                                                                    <TableRow>
                                                                        <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff" }}>
                                                                            {'N/A'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#0070c0", color: '#fff' }} >
                                                                    Qualifying Indoor Models
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {((productMargin !== 'N/A' && qRODMargin !== 0) && lookUpValidate && lookUpMargin) ? (lookUpMargin?.map((row, rowIndex) => (
                                                                <TableRow key={rowIndex}>
                                                                    <TableCell key={rowIndex} align="center" className='resultTable tableFields' style={(lookUpValidate['11DigitModel'].filter(x => row?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : row ? { background: '#fff', border: '1px solid rgba(224, 224, 224, 1)' } : { border: 'unset' }}>
                                                                        {row}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                            )
                                                                :
                                                                (
                                                                    <TableRow>
                                                                        <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff" }}>
                                                                            {'N/A'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: '0px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="center" className='resultTable tableFields' style={{ background: "#0070c0", color: '#fff' }} >
                                                                    Qualifying Indoor Models
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {((productLast !== 'N/A' && qRODLast !== 0) && lookUpValidate && lookUpLast) ? (lookUpLast?.map((row, rowIndex) => (
                                                                <>

                                                                    <TableRow key={rowIndex}>
                                                                        <TableCell key={rowIndex} align="center" className='resultTable tableFields' style={(lookUpValidate['11DigitModel'].filter(x => row?.includes(x)).length > 0) ? { background: "#00b050", color: '#fff' } : row ? { background: '#fff', border: '1px solid rgba(224, 224, 224, 1)' } : { border: 'unset' }}>
                                                                            {row}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            ))
                                                            )
                                                                :
                                                                (
                                                                    <TableRow>
                                                                        <TableCell align="center" className='resultTable tableFields' style={{ background: "#fff" }}>
                                                                            {'N/A'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Grid>
                    </Grid>
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            IRA TAX TOOL
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            x
                        </IconButton>
                        <DialogContent dividers>
                            {(isMargin) ? (
                                <span>Must be between 0% and 100%</span>

                            ) : (
                                <span>Must be between $0 and $15,000</span>

                            )

                            }
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                                style={{background:'#7030a0'}}
                            >
                                Close
                            </Button>


                        </DialogActions>
                    </BootstrapDialog>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoad}
                        onClick={handleLoadClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Grid>
            )}
        </Container>
    );
};



export default TableLayout;